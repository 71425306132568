import React, { FC, useState } from 'react';
import { ZazumeModal, ZZMForm } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { getInvestmentAlertSchema, InvestmentAlertFormInputs } from '#/pages/investment/alerts/components/modal/InvestmentAlert.schema';
import { City } from '#/models/location/City';
import { CreateAlertModalFooter } from '#/pages/investment/alerts/components/modal/CreateAlertModalFooter';
import { CreateAlertModalBody } from '#/pages/investment/alerts/components/modal/CreateAlertModalBody';
import { useAsyncMethod } from '#hooks/useAsyncMethod';
import { API } from '#lib/network/API';
import { useNotifications } from '#/hooks';
import { CurrencyVO, PriceVO } from '@zazume/zzm-utils';
import MetricsHelper from '#/utils/metrics/metricsHelper';

type CreateAlertModalProps = {
  onClose?: () => void;
  onSubmit?: () => void;
}

const isCity = (value: any): value is City => {
  return value && value.id && value.name;
};

export const CreateAlertModal: FC<CreateAlertModalProps> = ({ onClose, onSubmit }) => {
  const { t } = useI18n();
  const { call } = useAsyncMethod();
  const formSchema = getInvestmentAlertSchema(t);
  const [city, setCity] = useState<City>();
  const { showNotification } = useNotifications();

  const handleSubmit = (formData: InvestmentAlertFormInputs) => call(async () => {
    if (!isCity(city)) {
      return;
    }

    await API.investment.alerts.create({
      ...formData, location: {
        city
      },
      maxBudget: PriceVO.fromAmount(formData.maxBudget * 100, CurrencyVO.fromPrimitive('eur')).toPrimitive(),
      minRentPrice: PriceVO.fromAmount(formData.minRentPrice * 100, CurrencyVO.fromPrimitive('eur')).toPrimitive()
    });

    MetricsHelper.track('Add Investment Alert Completed');
    showNotification(t('investment.alertModal.success'), 'success');
    onSubmit?.();
    onClose?.();
  });

  return (
    <ZazumeModal title={t('investment.alertModal.title')} withCloseButton={false}>
      <ZZMForm.Form validation={formSchema} onSubmit={handleSubmit}>
        <CreateAlertModalBody setCity={setCity}/>
        <CreateAlertModalFooter/>
      </ZZMForm.Form>
    </ZazumeModal>
  );
};
