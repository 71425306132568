import { FC } from 'react';
import { TopNavigation } from '../components/TopNavigation';
import { Sections } from '../Sections';
import { SectionGroup } from '../components/SectionGroup';
import { FooterNavigation } from '../components/FooterNavigation';
import { CommercializationUpgradeButton } from '../components/CommercializationUpgradeButton';
import { useI18n } from '#/hooks/useI18n';
import { useAuth } from '#/contexts/AuthProvider';
import { useSession } from '#/hooks/session/useSession';

interface Props {
  isMobile: boolean;
  isOpen: boolean;
  onClickItem?: () => void;
}

export const OwnerNavigation: FC<Props> = ({ isMobile, isOpen, onClickItem }) => {
  const { t } = useI18n();
  const { organization } = useAuth();
  const { user } = useSession();

  return <>
    <TopNavigation dataId="menu" open={isOpen}>
      <Sections.Home onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Investment onClickItem={onClickItem} isMobile={isMobile}/>
      <SectionGroup id="commercialisation" icon="key" isNavigationOpen={isOpen} title={t('asideNavigation.commercialisation')} isMobile={isMobile} canHasRoles={['ownerBasic', 'ownerMiddle', 'ownerManager']} shouldHideToBlacklistUsers>
        <Sections.Applications onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Valuations onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="management" icon="folder" title={t('asideNavigation.management')} isNavigationOpen={isOpen} isMobile={isMobile} canHasRoles={['ownerBasic', 'ownerManager', 'ownerMiddle']}>
        <Sections.Incidents onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="accounting" icon="directOrder" title={t('accounting.title')} isNavigationOpen={isOpen} isMobile={isMobile} canHasRoles={['ownerManager', 'ownerMiddle']}>
        <Sections.Payments onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.BankAccounts onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Invoices onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Taxes onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <Sections.Portfolio onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.People onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Marketplace onClickItem={onClickItem} isMobile={isMobile}/>
    </TopNavigation>
    <FooterNavigation>
      {isOpen && user.owner?.ownerAccountId && organization?.isDefaultOrganization && <CommercializationUpgradeButton ownerAccountId={user.owner?.ownerAccountId}/>}
      <Sections.OwnerAccountSettings onClickItem={onClickItem} isMobile={isMobile}/>
    </FooterNavigation>
  </>;
};
