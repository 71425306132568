import { PaginationOptions } from '#/hooks';
import { AgentOwnerLead } from '#/models/AgentOwnerLead';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { AgentOwnerLeadFormInputs } from '#/pages/agentOwnerLead/components/modals/AgentOwnerLeadForm.schema';
import { AgentOwnerLeadStatusFormInputs } from '#/pages/agentOwnerLead/components/modals/AgentOwnerLeadStatusForm.schema';

export const agentOwnerLeads = {
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<AgentOwnerLead[]>(toPaginatedURL(`/dashboard/agent-owner-leads${params.url}${params.queryParams}`, pagination)),
  get: (agentOwnerLeadId: IdentifierDTO) => (): Promise<AgentOwnerLead> =>
    doGet<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`),
  create: () => (newAgentOwnerLead: AgentOwnerLeadFormInputs): Promise<AgentOwnerLead> =>
    doPost('/dashboard/agent-owner-leads', newAgentOwnerLead),
  delete: (agentOwnerLeadId: IdentifierDTO): Promise<void> =>
    doDelete(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`),
  update: (agentOwnerLeadId: IdentifierDTO) => (updatedAgentOwnerLead: AgentOwnerLeadFormInputs) =>
    doPut<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`, updatedAgentOwnerLead),
  updateStatus: (agentOwnerLeadId: IdentifierDTO) => (body: AgentOwnerLeadStatusFormInputs) =>
    doPut<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}/status`, body)
};
