import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.s10} ${theme.spacing.s7};
  gap: ${theme.spacing.s4};

  ${theme.mq.tablet} {
    padding: ${theme.spacing.s10} ${theme.spacing.s15};
  }

  ${theme.mq.desktop} {
    padding: ${theme.spacing.s10} ${theme.spacing.s11};
  }
`);

interface Props {
}

export const FooterNavigation: FC<Props> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};
