import { PaginationOptions } from '#/hooks';
import { BasicUnit } from '#/models';
import { Invoice } from '#/models/Invoice';
import { ExportInvoicesInputs } from '#/pages/accounting/invoices/components/ExportInvoices.schema';
import { SendInvoiceEmailInputs } from '#/pages/accounting/invoices/components/SendInvoiceEmail.schema';
import { AttachExternalInvoiceRequest } from '#lib/network/api/invoices/requests/AttachExternalInvoiceRequest';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';

export const invoices = {
  create: (body: object): Promise<Invoice> =>
    doPost('/dashboard/invoices/create', body),
  update: (invoiceId: IdentifierDTO): Promise<Invoice> =>
    doPut(`/dashboard/invoices/${invoiceId}`),
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<Invoice[]>(toPaginatedURL(`/dashboard/invoices${params.url}${params.queryParams}`, pagination)),
  sendByEmail: (body: SendInvoiceEmailInputs, invoiceId: IdentifierDTO) =>
    doPost(`/dashboard/invoices/${invoiceId}/send-email`, body),
  export: (body: ExportInvoicesInputs, organizationId: IdentifierDTO) =>
    doPost(`/dashboard/invoices/export/${organizationId}`, body),
  getInvoiceUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/dashboard/invoices/units/organization/${organizationId}`),
  attachExternalInvoice: (request: AttachExternalInvoiceRequest) =>
    doPostFileUpload<void>('/dashboard/invoices/external', request.build()),
  fromExpense: (expenseId: IdentifierDTO) => () =>
    doGet<Invoice[]>(`/dashboard/invoices/from-expense/${expenseId}`),
  fromIncome: (incomeId: IdentifierDTO) => () =>
    doGet<Invoice[]>(`/dashboard/invoices/from-income/${incomeId}`),
  deleteExternalInvoice: (invoiceId: IdentifierDTO) =>
    doDelete(`/dashboard/invoices/external/${invoiceId}`)
};
