export class VersionedStorage {
  static setItem(key: string, data: any, version: string): void {
    localStorage.setItem(key, JSON.stringify({ data, version }));
  }

  static getItem<T>(key: string, version: string): T | null {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    try {
      const parsed = JSON.parse(item);
      if (parsed.version !== version) {
        localStorage.removeItem(key);
        return null;
      }
      return parsed.data;
    } catch (error) {
      console.error('Error parsing localStorage item:', error);
      localStorage.removeItem(key);
      return null;
    }
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
