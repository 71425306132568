import { Align, Flex, Justify, Phone, Theme, Whatsapp } from '@zazume/zzm-base';
import { LanguageVO } from '@zazume/zzm-utils';
import { FC } from 'react';
import { useI18n } from '#/hooks/useI18n';
import { formatPhone } from '#/utils/phoneHelper';
import { linkWhatsapp } from '#/utils/whatsappHelper';
import { Link } from '../typography';
import { RespondioButton } from './RespondioButton';
import { Can } from '../roles/Can';
import { IconSize } from '@zazume/zzm-base/dist/components/icons/utils';

export interface ButtonsContactProps {
  email?: string;
  phone: string;
  countryCode: number;
  name: string;
  // TODO change to LanguageVO ASAP
  lang: string;
  justify?: Justify;
  align?: Align;
  gap?: keyof Theme['spacing'];
  direction?: 'row' | 'column';
  size?: IconSize;
}

export const ButtonsContact: FC<ButtonsContactProps> = ({ size = 20, email, phone, countryCode, name, lang, gap = 's3', align = 'center', justify, direction = 'row' }) => {
  const { t } = useI18n();

  return (
    <Flex direction={direction} gap={gap} align={align} justify={justify}>
      <Link href={linkWhatsapp(phone, countryCode, t('whatsapp.message', { name, lng: LanguageVO.from(lang).toPlatform() }))} target="_blank" stopPropagation>
        <Whatsapp size={size} color="Gray400"/>
      </Link>
      <Link href={`tel:${formatPhone(phone, countryCode.toString())}`} target="_blank" stopPropagation>
        <Phone size={size} color="Gray400"/>
      </Link>
      {email && <Can hasRoles={['admin', 'buildingManager']}>
        <RespondioButton email={email}/>
      </Can>}
    </Flex>
  );
};
