export const Events = <const>[
  'Add Bank Account Linked Btn',
  'Add Bank Account Linked Completed',
  'Add Expense Btn',
  'Add Expense Completed',
  'Add Investment Alert Btn',
  'Add Investment Alert Completed',
  'Add Lease Btn',
  'Add Lease Completed',
  'Add Provider Btn',
  'Add Provider Completed',
  'Add Recurrent Expense Btn',
  'Add Recurrent Expense Completed',
  'Add Tenant Btn',
  'Add Tenant Completed',
  'Add Unit Btn',
  'Add Unit Completed',
  'Create Property Valuation Btn',
  'Create Property Valuation Completed',
  'Import Expenses Btn',
  'Import Expenses Completed',
  'Import Incomes Btn',
  'Import Incomes Completed',
  'Purchase Investment Product Btn',
  'Purchase Investment Product Intitiated',
  'Purchase Investment Product Completed',
  'Purchase Owner Product Intitiated',
  'Purchase Owner Product Completed',
  'Purchase P2P Initiated',
  'Purchase P2P Completed',
  'Request Commercialization Service Upgrade Btn',
  'Request Commercialization Tenant Search Service Upgrade Completed',
  'Save Billing Data',
  'Save Investment Opportunity Completed',
  'Save Investment Opportunity Removed',
  'Start Taxes Btn',
  'Start Taxes Completed',
  'Upload Document Btn',
  'Upload Document Completed'
];
export type EventType = typeof Events[number];
