import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MetricsHelper from '#/utils/metrics/metricsHelper';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    MetricsHelper.sendPageView(location.pathname + location.search);
  }, [location]);
};
