import Sentry from './sentryHelper';
import Hotjar from './hotjarHelper';
import Segment from './segmentHelper';
import { EventType, Events } from './events/events';
import { UserInformation } from '#/models/UserInformation';
import { GoogleAnalytics } from './GoogleAnalytics';
import { API } from '#/lib/network/API';

export default class MetricsHelper {
  private static instance: MetricsHelper;
  private googleAnalytics: GoogleAnalytics;
  private segment: Segment;
  private hotjar: Hotjar;
  private sentry: Sentry;

  private constructor() {
    this.googleAnalytics = new GoogleAnalytics();
    this.segment = new Segment();
    this.hotjar = new Hotjar();
    this.sentry = new Sentry();
  }

  private static getInstance(): MetricsHelper {
    if (!this.instance) {
      this.instance = new MetricsHelper();
    }
    return this.instance;
  }

  static startMetrics() {
    MetricsHelper.getInstance().startMetrics();
  }

  private startMetrics() {
    this.googleAnalytics.start();
    this.segment.start();
    this.hotjar.start();
    this.sentry.start();
  }

  static async identifyUser() {
    const userInformation = await API.userInformation.me();
    MetricsHelper.getInstance().identifyUser(userInformation);
  }

  private identifyUser(userInformation: UserInformation) {
    this.googleAnalytics.setUser(userInformation);
    this.segment.setUser(userInformation);
    this.hotjar.setUser(userInformation);
    this.sentry.setUser(userInformation);
  }

  static clearUser() {
    this.getInstance().clearUser();
  }

  private clearUser() {
    this.googleAnalytics.clear();
    this.segment.clear();
    this.sentry.clear();
  }

  static track(eventType: EventType, event?: object) {
    this.getInstance().track(eventType, event);
  }

  private track(eventType: EventType, event?: object) {
    this.segment.trackEvent(eventType, event);
  }

  static sendPageView(path: string) {
    this.getInstance().sendPageView(path);
  }

  static isValidEvent(eventType: string): eventType is EventType {
    return Events.includes(eventType as any);
  }

  private sendPageView(path: string) {
    this.googleAnalytics.sendPageView(path);
    this.segment.trackPageView(path);
  }

  static reportError(error: Error, errorInfo) {
    this.getInstance().reportError(error, errorInfo);
  }

  private reportError(error: Error, errorInfo) {
    this.sentry.reportError(error, errorInfo);
  }
}
