import { FC } from 'react';
import { TopNavigation } from '../components/TopNavigation';
import { Sections } from '../Sections';
import { SectionGroup } from '../components/SectionGroup';
import { NavigationLink } from '../components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#/hooks/useI18n';

interface Props {
  isMobile: boolean;
  isOpen: boolean;
  onClickItem?: () => void;
}

export const TeamNavigation: FC<Props> = ({ isMobile, isOpen, onClickItem }) => {
  const { t } = useI18n();

  return <>
    <TopNavigation dataId="menu" open={isOpen}>
      <Sections.Home onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Calendar onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Investment onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.TaskManager onClickItem={onClickItem} isMobile={isMobile}/>
      <SectionGroup id="commercialisation" icon="key" isNavigationOpen={isOpen} title={t('asideNavigation.commercialisation')} isMobile={isMobile} canHasRoles={['admin', 'agent', 'buildingManager', 'commercial', 'ownerBasic', 'ownerMiddle', 'ownerManager', 'zazume']} shouldHideToBlacklistUsers>
        <Sections.Applications onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Valuations onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="management" icon="folder" title={t('asideNavigation.management')} isNavigationOpen={isOpen} isMobile={isMobile} canHasRoles={['admin', 'buildingManager', 'commercial', 'ownerBasic', 'ownerManager', 'ownerMiddle', 'staff', 'zazume']}>
        <Sections.Incidents onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Bookings onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Documents onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="accounting" icon="directOrder" title={t('accounting.title')} isNavigationOpen={isOpen} isMobile={isMobile} canHasRoles={['admin', 'buildingManager', 'commercial', 'ownerManager', 'ownerMiddle', 'zazume']}>
        <Sections.Payments onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.NonPayments onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.BankAccounts onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Invoices onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Taxes onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <Sections.Portfolio onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.People onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Marketplace onClickItem={onClickItem} isMobile={isMobile}/>
      <SectionGroup id="admin" title={t('asideNavigation.organization')} isNavigationOpen={isOpen} icon="building" isMobile={isMobile} canHasRoles={['admin']}>
        <Sections.Team onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Templates onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.OrganizationServices onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Admin onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="zazume" title="Zazume" isNavigationOpen={isOpen} icon="zazumeIsotype" canHasRoles={['zazume']}>
        <NavigationLink to={Private.SERVICES.route} onClick={onClickItem} icon="marketplace" title={t('asideNavigation.services')} isMobile={isMobile} depth={1}/>
        <NavigationLink to={Private.ADMIN.route} onClick={onClickItem} icon="settings" title={t('asideNavigation.admin')} isMobile={isMobile} depth={1}/>
      </SectionGroup>
    </TopNavigation>
  </>;
};
