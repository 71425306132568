import { useCallback } from 'react';
import { useURLParams } from './useURLParams';

interface UseFilterResult<T> {
  filter?: T;
  filterByName: (filter: T) => void;
  source: 'param' | 'localStorage';
  localStorageKey?: string;
  clear: () => void;
  set: (value: T) => void;
}

const Filters = <const>[
  'ACCOUNTING_DASHBOARD_INFO_TYPE',
  'ACCOUNT_MANAGER',
  'ADVERTISER_TYPE',
  'AGENT_ID',
  'AGENT_OWNER_LEAD_STATUS',
  'AGENT_OWNER_LEAD_SOURCE',
  'AMOUNT_MAX',
  'AMOUNT_MIN',
  'APPLICATION_MANAGER',
  'APPLICATION_PREQUALIFICATION',
  'APPLICATION_RELATED_TO_ALERT',
  'APPLICATION_STEP',
  'APPLICATION_TYPE',
  'ARCHIVED_APPLICATION_STATUS',
  'AUTHOR',
  'BANK_ACCOUNT',
  'BANK_TRANSACTION_CONCEPT',
  'BANK_TRANSACTION_STATUS',
  'BANK_TRANSACTION_TYPE',
  'BENEFICIARY',
  'BOOKING_STATUS',
  'BOOKING_TYPE',
  'BUILDING',
  'CALENDAR_EVENT_TYPE',
  'CATEGORY',
  'CHARACTERISTICS',
  'CITY_ID',
  'CLAIM_NUMBER',
  'CONSENT',
  'CREDITOR',
  'DASHBOARD_COMMERCIALISATION_ACTOR',
  'DASHBOARD_MANAGEMENT_ACTOR',
  'DATE',
  'DISCOUNT',
  'DOCUMENT_TEMPLATE_TYPE',
  'END_DATE',
  'EXPENSE_STATUS',
  'EXPENSE_TYPE',
  'FLOOR_SEGMENT',
  'HAS_ELEVATOR',
  'INCIDENT_CATEGORY',
  'INCIDENT_MANAGED_BY',
  'INCIDENT_MANAGER',
  'INCIDENT_PRIORITY',
  'INCIDENT_STATE',
  'INVOICE_NUMBER',
  'INVOICE_TYPE',
  'LEAD_STATUS',
  'MIN_ROOMS',
  'PAYER',
  'PAYMENT_STATUS',
  'PAYMENT_TYPE',
  'PAYOUT_RECIPIENT',
  'PAYOUT_STATUS',
  'PAYOUT_TYPE',
  'PROFITABILITY_MIN',
  'PROPERTY_TYPE',
  'PROVIDER_SERVICE',
  'PUBLISHED_STATUS',
  'REFERENCE_NUMBER',
  'RENT_AMOUNT_MAX',
  'RENT_AMOUNT_MIN',
  'SERVICE_AREA',
  'STAFF_ID',
  'START_DATE',
  'STATUS',
  'SPECIAL_CONDITIONS',
  'TENANT_STATUS',
  'UNIT_ID',
  'UNIT_MANAGER',
  'UNIT_STATUS',
  'UNIT_CONDITION',
  'WORKSPACE',
  'YEAR'
];
export type FiltersTypes = typeof Filters[number];

type AtomsTypes = {
  [key in FiltersTypes]: IAtom;
}

interface IAtom {
  filter: string,
  atomKey: string
}

export const Atoms: AtomsTypes = {
  SPECIAL_CONDITIONS: {
    filter: 'specialConditions',
    atomKey: 'specialConditions'
  },
  HAS_ELEVATOR: {
    filter: 'hasElevator',
    atomKey: 'hasElevator'
  },
  ACCOUNTING_DASHBOARD_INFO_TYPE: {
    filter: 'dashboardInfoType',
    atomKey: 'dashboardInfoType'
  },
  ACCOUNT_MANAGER: {
    filter: 'accountManager',
    atomKey: 'accountManager'
  },
  ADVERTISER_TYPE: {
    filter: 'advertiserType',
    atomKey: 'advertiserType'
  },
  AGENT_ID: {
    filter: 'agentId',
    atomKey: 'agentId'
  },
  AGENT_OWNER_LEAD_STATUS: {
    filter: 'status',
    atomKey: 'agentOwnerLeadStatus'
  },
  AGENT_OWNER_LEAD_SOURCE: {
    filter: 'source',
    atomKey: 'agentOwnerLeadSource'
  },
  AMOUNT_MAX: {
    filter: 'maxAmount',
    atomKey: 'maxAmount'
  },
  AMOUNT_MIN: {
    filter: 'minAmount',
    atomKey: 'minAmount'
  },
  APPLICATION_MANAGER: {
    filter: 'manager',
    atomKey: 'applicationManager'
  },
  APPLICATION_PREQUALIFICATION: {
    filter: 'prequalification',
    atomKey: 'applicationPrequalification'
  },
  APPLICATION_RELATED_TO_ALERT: {
    filter: 'relatedToAlert',
    atomKey: 'applicationRelatedToAlert'
  },
  APPLICATION_STEP: {
    filter: 'step',
    atomKey: 'applicationStep'
  },
  APPLICATION_TYPE: {
    filter: 'type',
    atomKey: 'applicationType'
  },
  ARCHIVED_APPLICATION_STATUS: {
    filter: 'status',
    atomKey: 'archivedApplicationStatus'
  },
  AUTHOR: {
    filter: 'author',
    atomKey: 'author'
  },
  BANK_ACCOUNT: {
    filter: 'bankAccount',
    atomKey: 'bankAccount'
  },
  BANK_TRANSACTION_CONCEPT: {
    filter: 'concept',
    atomKey: 'transactionConcept'
  },
  BANK_TRANSACTION_STATUS: {
    filter: 'status',
    atomKey: 'transactionStatus'
  },
  BANK_TRANSACTION_TYPE: {
    filter: 'type',
    atomKey: 'transactionType'
  },
  BENEFICIARY: {
    filter: 'beneficiary',
    atomKey: 'beneficiary'
  },
  BOOKING_STATUS: {
    filter: 'status',
    atomKey: 'bookingStatus'
  },
  BOOKING_TYPE: {
    filter: 'type',
    atomKey: 'bookingType'
  },
  BUILDING: {
    filter: 'building',
    atomKey: 'building'
  },
  CALENDAR_EVENT_TYPE: {
    filter: 'eventType',
    atomKey: 'eventType'
  },
  CATEGORY: {
    filter: 'category',
    atomKey: 'category'
  },
  CHARACTERISTICS: {
    filter: 'characteristics',
    atomKey: 'characteristics'
  },
  CITY_ID: {
    filter: 'cityId',
    atomKey: 'cityId'
  },
  CLAIM_NUMBER: {
    filter: 'claimNumber',
    atomKey: 'claimNumber'
  },
  CONSENT: {
    filter: 'consent',
    atomKey: 'consent'
  },
  CREDITOR: {
    filter: 'creditor',
    atomKey: 'creditor'
  },
  DASHBOARD_COMMERCIALISATION_ACTOR: {
    filter: 'commercialisationActor',
    atomKey: 'commercialisationActor'
  },
  DASHBOARD_MANAGEMENT_ACTOR: {
    filter: 'managementActor',
    atomKey: 'managementActor'
  },
  DATE: {
    filter: 'date',
    atomKey: 'date'
  },
  DISCOUNT: {
    filter: 'discount',
    atomKey: 'discount'
  },
  DOCUMENT_TEMPLATE_TYPE: {
    filter: 'type',
    atomKey: 'documentTemplateType'
  },
  END_DATE: {
    filter: 'endDate',
    atomKey: 'endDate'
  },
  EXPENSE_STATUS: {
    filter: 'status',
    atomKey: 'expenseStatus'
  },
  EXPENSE_TYPE: {
    filter: 'type',
    atomKey: 'expenseType'
  },
  FLOOR_SEGMENT: {
    filter: 'floorSegment',
    atomKey: 'floorSegment'
  },
  INCIDENT_CATEGORY: {
    filter: 'category',
    atomKey: 'incidentCategory'
  },
  INCIDENT_MANAGED_BY: {
    filter: 'managedBy',
    atomKey: 'incidentManagedBy'
  },
  INCIDENT_MANAGER: {
    filter: 'incidentsManager',
    atomKey: 'incidentsManager'
  },
  INCIDENT_PRIORITY: {
    filter: 'priority',
    atomKey: 'incidentPriority'
  },
  INCIDENT_STATE: {
    filter: 'state',
    atomKey: 'incidentState'
  },
  INVOICE_NUMBER: {
    filter: 'number',
    atomKey: 'invoiceNumber'
  },
  INVOICE_TYPE: {
    filter: 'type',
    atomKey: 'type'
  },
  LEAD_STATUS: {
    filter: 'leadStatus',
    atomKey: 'leadStatus'
  },
  MIN_ROOMS: {
    filter: 'minRooms',
    atomKey: 'minRooms'
  },
  PAYER: {
    filter: 'payer',
    atomKey: 'payer'
  },
  PAYMENT_STATUS: {
    filter: 'status',
    atomKey: 'paymentStatus'
  },
  PAYMENT_TYPE: {
    filter: 'type',
    atomKey: 'paymentType'
  },
  PAYOUT_RECIPIENT: {
    filter: 'recipient',
    atomKey: 'payoutRecipient'
  },
  PAYOUT_STATUS: {
    filter: 'status',
    atomKey: 'payoutStatus'
  },
  PAYOUT_TYPE: {
    filter: 'type',
    atomKey: 'payoutType'
  },
  PROFITABILITY_MIN: {
    filter: 'minProfitability',
    atomKey: 'minProfitability'
  },
  PROPERTY_TYPE: {
    filter: 'propertyType',
    atomKey: 'propertyType'
  },
  PROVIDER_SERVICE: {
    filter: 'providerService',
    atomKey: 'providerService'
  },
  PUBLISHED_STATUS: {
    filter: 'published_status',
    atomKey: 'publishedStatus'
  },
  REFERENCE_NUMBER: {
    filter: 'referenceNumber',
    atomKey: 'referenceNumber'
  },
  RENT_AMOUNT_MAX: {
    filter: 'rentMaxAmount',
    atomKey: 'rentMaxAmount'
  },
  RENT_AMOUNT_MIN: {
    filter: 'rentMinAmount',
    atomKey: 'rentMinAmount'
  },
  SERVICE_AREA: {
    filter: 'serviceArea',
    atomKey: 'serviceArea'
  },
  STAFF_ID: {
    filter: 'staffId',
    atomKey: 'staffId'
  },
  START_DATE: {
    filter: 'startDate',
    atomKey: 'startDate'
  },
  STATUS: {
    filter: 'status',
    atomKey: 'status'
  },
  TENANT_STATUS: {
    filter: 'status',
    atomKey: 'tenantStatus'
  },
  UNIT_CONDITION: {
    filter: 'unitCondition',
    atomKey: 'unitCondition'
  },
  UNIT_ID: {
    filter: 'unitId',
    atomKey: 'unitId'
  },
  UNIT_MANAGER: {
    filter: 'unitManager',
    atomKey: 'unitManager'
  },
  UNIT_STATUS: {
    filter: 'status',
    atomKey: 'unitStatus'
  },
  WORKSPACE: {
    filter: 'workspace',
    atomKey: 'workspace'
  },
  YEAR: {
    filter: 'year',
    atomKey: 'year'
  }
};

const extractSafeValue = <T>(value: T): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof (value as any).toString !== 'function') {
    throw new Error(`Value does not have a toString method. ${value}`);
  }

  return (value as any).toString();
};

export const useFilter = <T = string>(atomKey: FiltersTypes, defaultValue?: T, filterToStorage?: string): UseFilterResult<T> => {
  const selectedAtom: IAtom = Atoms[String(atomKey)];
  const { deleteParam, getParam, setParam } = useURLParams();

  const filterFromParam = getParam(selectedAtom.filter) || defaultValue;
  let filter = filterFromParam;
  const localStorageKey = filterToStorage ? `${filterToStorage}_${atomKey}` : undefined;

  if (filterToStorage && localStorageKey) {
    if (filterFromParam) {
      localStorage.setItem(localStorageKey, filterFromParam.toString());
    } else {
      const maxAmountFromLocalStorage = localStorage.getItem(localStorageKey);
      filter = maxAmountFromLocalStorage ? maxAmountFromLocalStorage : undefined;
    }
  }

  const filterByName = useCallback((auxFilter: T | undefined) => {
    auxFilter
      ? setParam(selectedAtom.filter, auxFilter as any)
      : deleteParam(selectedAtom.filter);
  }, [selectedAtom.filter, deleteParam, setParam]);

  const clear = useCallback(() => {
    deleteParam(selectedAtom.filter);
    if (localStorageKey) {
      localStorage.removeItem(localStorageKey);
    }
  }, [selectedAtom.filter, localStorageKey, deleteParam]);

  const set = useCallback((value: T) => {
    const safeValue = extractSafeValue(value);
    setParam(selectedAtom.filter, safeValue);
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, safeValue);
    }
  }, [localStorageKey, setParam, selectedAtom.filter]);

  return {
    source: filterFromParam || !Boolean(filterToStorage) ? 'param' : 'localStorage',
    filter: filter as T,
    filterByName,
    localStorageKey,
    clear,
    set
  };
};
