import { API } from '../../lib/network/API';
import { Private } from '../../containers/router/routes/Private';
import { useBrand } from '../useBrand';
import { useState } from 'react';
import { URLBuilder } from '@zazume/zzm-utils';
import { EndpointBuilder } from '../../lib/network/helpers';
import { Storage } from '../../utils/Storage';
import { EventType } from '#/utils/metrics/events/events';

interface UseStripePaymentResponse {
  openStripe: () => Promise<void>;
  goToPayment: (stripePriceId: string, metaData?: object, successMetricEvent?: EventType) => Promise<void>;
  isLoading: boolean;
}

export const useStripe = (): UseStripePaymentResponse => {
  const { brand } = useBrand();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openStripe = async () => {
    setIsLoading(true);
    const session = await API.checkout.getPortalSession(window.location.href);
    setIsLoading(false);
    window.location.href = session.url;
  };

  const goToPayment = async (stripePriceId: string, metaData?: object, successMetricEvent?: EventType) => {
    const returnURLBuilder = new URLBuilder(`${brand!.dashboardURL}${Private.CHECKOUT_SUCCESS.route}`);
    if (successMetricEvent) {
      returnURLBuilder.addQueryParam('metricEvent', successMetricEvent);
    }

    const metaDataQuery = EndpointBuilder.buildObjectQueryParam('meta-data', { ...metaData }).queryParams!;

    setIsLoading(true);
    const session = await API.checkout.getCheckoutSession(
      stripePriceId,
      returnURLBuilder.toString(),
      window.location.href,
      metaDataQuery
    );
    Storage.setStripeSessionKey(session.id);
    setIsLoading(false);

    window.location.href = session.url;
  };

  return {
    openStripe,
    goToPayment,
    isLoading
  };

};
