import { FC } from 'react';
import styled from '@emotion/styled';
import { ClipboardHelper, Copy } from '@zazume/zzm-base';
import { useI18n } from '#/hooks/useI18n';
import { useNotifications } from '#/hooks';

const Container = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`;

interface Props {
  value: string;
}

export const ClipboardCopyButton: FC<Props> = ({ value }) => {
  const { t } = useI18n();
  const { showNotification } = useNotifications();

  const copyToClipboard = (): void => {
    ClipboardHelper.writeText(value);
    showNotification(t('general.copiedToClipboard'));
  };

  return (
    <Container onClick={copyToClipboard}>
      <Copy size={20} color="Gray400"/>
    </Container>
  );
};
