import { FC } from 'react';
import { useSession } from '#hooks/session/useSession';
import { AgentNavigation } from './agentNavigation/AgentNavigation';
import { OwnerNavigation } from './ownerNavigation/OwnerNavigation';
import { TeamNavigation } from './teamNavigation/TeamNavigation';

interface Props {
  isMobile?: boolean;
  isOpen?: boolean;
  onClickItem?: () => void;
}

export const Navigation: FC<Props> = ({ isOpen = false, onClickItem, isMobile = false }) => {
  const { isOwner, isAgent } = useSession();

  if (isAgent) {
    return <AgentNavigation
      isMobile={isMobile}
      isOpen={isOpen}
      onClickItem={onClickItem}
    />;
  }

  if (isOwner) {
    return <OwnerNavigation
      isMobile={isMobile}
      isOpen={isOpen}
      onClickItem={onClickItem}
    />;
  }

  return <TeamNavigation
    isMobile={isMobile}
    isOpen={isOpen}
    onClickItem={onClickItem}
  />;
};
