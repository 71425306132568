import { TFunction } from '#hooks/useI18n';
import { I18NValues } from '#lib/i18n/generatedKeys';
import { OptionType } from '@zazume/zzm-base';
import { assertUnreachable } from '@zazume/zzm-utils';
import { AgentDocumentCategories, AgentDocumentTypes, BackgroundCheckDocumentTypes, BuildingDocumentTypes, DocumentCategories, DocumentType, IdCardsDocumentTypes, IncidentDocumentCategories, IncidentDocumentTypes, InsuranceDocumentTypes, InventoryDocumentTypes, NonPaymentCategories, NonPaymentDocumentTypes, OwnerAccountDocumentCategories, OwnerServiceCommercialisationContractTypes, OwnerServiceContractCategories, OwnerServiceContractTypes, OwnerServiceManagementContractTypes, ProviderDocumentCategories, ProviderDocumentTypes, RelatedModelType, RemodelingDocumentTypes, TenantDocumentCategories, TenantInvoicesDocumentTypes, UnitDocumentCategories, UnitInvoicesDocumentTypes } from '../../../models';

export const getDocumentTypesOptions = (category: DocumentCategories, relatedModel: RelatedModelType, t: TFunction): OptionType[] => {
  const toOption = (type) => {
    return { value: type, name: t(`documents.types.${type}` as I18NValues) };
  };

  switch (category) {
    case 'inventory':
      return InventoryDocumentTypes.map(toOption);
    case 'invoices':
      return relatedModel === 'User' ? TenantInvoicesDocumentTypes.map(toOption) : UnitInvoicesDocumentTypes.map(toOption);
    case 'building':
      return BuildingDocumentTypes.map(toOption);
    case 'remodeling':
      return RemodelingDocumentTypes.map(toOption);
    case 'idCards':
      return IdCardsDocumentTypes.map(toOption);
    //TODO FIX THIS CASE. replace this with tenantInvoices? or unitInvoices & tenantInvoices
    // case 'invoices':
    //   return TenantInvoicesDocumentTypes.map(toOption);
    case 'backgroundCheck':
      return BackgroundCheckDocumentTypes.map(toOption);
    case 'provider':
      return ProviderDocumentTypes.map(toOption);
    case 'agent':
      return AgentDocumentTypes.map(toOption);
    case 'incident':
      return IncidentDocumentTypes.map(toOption);
    case 'insurance':
      return InsuranceDocumentTypes.map(toOption);
    case 'ownerServiceContract':
      return OwnerServiceContractTypes.map(toOption);
    case 'ownerServiceCommercialisationContract':
      return OwnerServiceCommercialisationContractTypes.map(toOption);
    case 'ownerServiceManagementContract':
      return OwnerServiceManagementContractTypes.map(toOption);
    case 'nonPayment':
      return NonPaymentDocumentTypes.map(toOption);
    case 'holdingDepositContract':
    case 'payments':
    case 'rentalInsurance':
    case 'otherContractDocuments':
    case 'others':
    case 'other':
      return [toOption('other')];
    default:
      assertUnreachable(category);
  }
};

export const getDocumentCategoriesOptions = (related: RelatedModelType, t: TFunction, hiddenCategories?: DocumentCategories[]): OptionType[] => {
  const toOption = (category) => (type) => {
    return { value: type, name: t(`documents.${category}.categories.${type}` as I18NValues) };
  };

  const filterHidden = (category) =>
    !hiddenCategories?.includes(category);

  switch (related) {
    case 'Incident':
      return IncidentDocumentCategories.filter(filterHidden).map(toOption('incident'));
    case 'User':
      return TenantDocumentCategories.filter(filterHidden).map(toOption('tenant'));
    case 'Unit':
      return UnitDocumentCategories.filter(filterHidden).map(toOption('unit'));
    case 'Provider':
      return ProviderDocumentCategories.filter(filterHidden).map(toOption('provider'));
    case 'Agent':
      return AgentDocumentCategories.filter(filterHidden).map(toOption('agent'));
    case 'OwnerAccount':
      return OwnerAccountDocumentCategories.filter(filterHidden).map(toOption('ownerAccount'));
    case 'OwnerServiceContract':
      return OwnerServiceContractCategories.filter(filterHidden).map(toOption('ownerServiceContract'));
    case 'NonPayment':
      return NonPaymentCategories.filter(filterHidden).map(toOption('nonPayment'));
    case 'Contract':
    case 'Guarantor':
    case 'Sharer':
      return [toOption('common')('other')];
    default:
      assertUnreachable(related);
  }
};

export const findDocumentCategory = (type: DocumentType, relatedModel?: RelatedModelType): DocumentCategories => {
  switch (type) {
    case 'inventory':
    case 'contraInventory':
      return 'inventory';

    case 'waterSupplyInvoice':
    case 'electricityCupsCIEInvoice':
    case 'gasCupsInvoice':
    case 'internetConnectionInvoice':
      return 'invoices';

    case 'occupancyCertificate':
    case 'energyPerformanceCertificate':
    case 'cadastralReport':
      return 'building';

    case 'remodelingGeneral':
      return 'remodeling';

    case 'idFront':
    case 'idBack':
    case 'passport':
      return 'idCards';

    case 'budget':
      return 'incident';

    case 'payslip':
    case 'employmentContract':
    case 'pensionForm':
    case 'taxForm100':
    case 'freelanceInvoices':
      return 'backgroundCheck';

    case 'provider':
      return 'provider';

    case 'homeInsurance':
    case 'otherInsurance':
      return 'insurance';

    case 'otherContract':
    case 'partnershipContract':
    case 'viewingsContract':
    case 'agent':
      return 'agent';

    case 'other':
      switch (relatedModel) {
        case 'Incident':
          return 'incident';
        default:
          return 'other';
      }
    default:
      return 'other';
  }
};

export const getOption = (options: OptionType[], findingValue: string): OptionType =>
  options.find(({ value }) => value === findingValue)!;
