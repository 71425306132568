import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

const Container = styled.nav<any>(({ theme, open }) => css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.s8} ${theme.spacing.s7};
  gap: ${theme.spacing.s4};

  ${theme.mq.tablet} {
    padding: ${theme.spacing.s8} ${theme.spacing.s15};
  }

  ${theme.mq.desktop} {
    height: 100%;
    padding: ${open ? '0.938rem 1.875rem' : '1.063rem 0.938rem'};
  }
`);

interface Props {
  dataId?: string;
  open: boolean;
}

export const TopNavigation: FC<Props> = ({ open, dataId, children }) => {
  return (
    <Container open={open} data-id={dataId}>
      {children}
    </Container>
  );
};
