import { PaginationOptions } from '#/hooks';
import { Application, ApplicationComment, ApplicationOptionalStateType, ApplicationRequestBody, BasicPropertyValuation, BasicUnit, BasicUser, Building, CancellationPolicy, ContactDetails, Currency, Document, Message, OwnerAccount, PlaybookTask, Product, PropertyValuation, Review, Service, ServiceHighlight, Subcategory, Tenant, Unit, User, Utility, Workspace } from '#/models';
import { DeclineApplicationRequest } from '#/models/application/DeclineApplicationRequest';
import { Bank } from '#/models/banking/Bank';
import { BankAccountLinked } from '#/models/banking/BankAccountLinked';
import { Country } from '#/models/Country';
import { GenericUser } from '#/models/GenericUser';
import { Inventory } from '#/models/Inventory';
import { MemberOfTeam } from '#/models/MemberOfTeam';
import { OwnerLead } from '#/models/OwnerLead';
import { PortalIntegration, PortalIntegrationTypes, UnitsPublicationNotAvailableStatusInPortal } from '#/models/portalIntegration';
import { PreScoring } from '#/models/PreScoring';
import { ForecastedExpense } from '#/models/recurrent/ForecastedExpense';
import { ForecastedIncome } from '#/models/recurrent/ForecastedIncome';
import { RecurrentExpense } from '#/models/recurrent/RecurrentExpense';
import { RecurrentIncome } from '#/models/recurrent/RecurrentIncome';
import { RecurrentPayout } from '#/models/recurrent/RecurrentPayout';
import { SearchToken } from '#/models/Search';
import { Sepa } from '#/models/Sepa';
import { Viewing } from '#/models/Viewing';
import { Zazume } from '#/models/Zazumes';
import { AddRecurrentDiscountFormData } from '#/pages/accounting/components/addRecurrentDiscountModal/AddRecurrentDiscountModal.schema';
import { EditRecurrentExpenseInputs } from '#/pages/accounting/recurrentExpenses/detail/components/editRecurrentExpense/EditRecurrentExpense.schema';
import { EditRecurrentIncomeInputs } from '#/pages/accounting/recurrentIncomes/detail/components/editRecurrentIncome/EditRecurrentIncome.schema';
import { accountingNotes } from '#lib/network/api/accountingNotes/accountingNotes';
import { afterbanks } from '#lib/network/api/afterbanks/afterbanks';
import { SendHoldingDepositRequest } from '#lib/network/api/application/models/SendHoldingDepositRequest';
import { autoReconciliations } from '#lib/network/api/autoReconciliations/autoReconciliations';
import { investment } from '#lib/network/api/investment/investment';
import { IdentifierDTO } from '@zazume/zzm-base';
import { accounting } from './api/accounting/accounting';
import { activity } from './api/activity/activity';
import { actor } from './api/actor/actor';
import { agentOwnerLeads } from './api/agentOwnerLead/agentOwnerLeads';
import { agents } from './api/agents/agents';
import { application } from './api/application/application';
import { auth } from './api/auth/auth';
import { backgroundCheck } from './api/backgroundCheck/backgroundCheck';
import { bankTransactions } from './api/bankTransactions/bankTransactions';
import { bookings } from './api/bookings/bookings';
import { brands } from './api/brand/brands';
import { calendar } from './api/calendar/calendar';
import { checkout } from './api/checkout/checkout';
import { cities } from './api/cities/cities';
import { comments } from './api/comments/comments';
import { contracts } from './api/contracts/contracts';
import { copilot } from './api/copilot/copilot';
import { dashboard } from './api/dashboard/dashboard';
import { documents } from './api/documents/documents';
import { CreateDocumentRequest } from './api/documents/requests/CreateDocumentRequest';
import { UpdateDocumentRequest } from './api/documents/requests/UpdateDocumentRequest';
import { documentTemplate } from './api/documentTemplate/documentTemplate';
import { documentUploadLinks } from './api/documentUploadLink/documentUploadLink';
import { drafts } from './api/drafts/drafts';
import { expenses } from './api/expenses/expenses';
import { incidents } from './api/incidents/incidents';
import { invitations } from './api/invitations/Invitations';
import { invoices } from './api/invoices/invoices';
import { keyDelivery } from './api/keyDelivery/keyDelivery';
import { landings } from './api/landings/landings';
import { messaging } from './api/messaging/messaging';
import { n43 } from './api/n43/n43';
import { nonPayments } from './api/nonPayments/nonPayments';
import { notifications } from './api/notifications';
import { notificationSettings } from './api/notificationSettings/notificationSettings';
import { organizationIntegrationsSettings } from './api/organizationIntegrationsSettings/organizationIntegrationsSettings';
import { organizations } from './api/organizations/organizations';
import { ownerAccount } from './api/ownerAccount/ownerAccount';
import { ownerServiceContract } from './api/ownerServiceContract/ownerServiceContract';
import { ownerTaxes } from './api/ownerTaxes/ownerTaxes';
import { payments } from './api/payments/payments';
import { payouts } from './api/payouts/payouts';
import { providers } from './api/providers/providers';
import { sepaPayments } from './api/sepaPayments/sepaPayments';
import { tasks } from './api/tasks/tasks';
import { taskTemplate } from './api/taskTemplate/taskTemplate';
import { units } from './api/unit/units';
import { viewings } from './api/viewings/viewings';
import { doDelete, doGet, doPatchFileUpload, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from './helpers';
import { userInformation } from './api/userInformation/userInformation';

export const API = {
  accounting,
  accountingNotes,
  activity,
  actor,
  afterbanks,
  agents,
  agentOwnerLeads,
  application,
  auth,
  autoReconciliations,
  backgroundCheck,
  bankTransactions,
  banking: {
    getBanksByCountryCode: (countryCode: string) =>
      () =>
        doGet<Bank[]>(`/dashboard/banking/banks/${countryCode}`),
    getAccountLinkedByBank: (bankId: IdentifierDTO) =>
      doGet<any>(`/dashboard/banking/account-link/${bankId}`),
    updateAliasById: (id: IdentifierDTO, alias: string) =>
      doPut<any>(`/dashboard/banking/account/${id}/alias`, { alias }),
    getBankAccountLinked: () =>
      doGet<BankAccountLinked[]>('/dashboard/banking'),
    deleteAccount: (id: IdentifierDTO, removeTransactions: boolean) =>
      doDelete(`/dashboard/banking/account/${id}?removeTransactions=${removeTransactions}`),
    renewAccountLinked: (bankAccountLinkedId: IdentifierDTO) =>
      doGet<any>(`/dashboard/banking/account-renew-link/${bankAccountLinkedId}`)
  },
  bookings,
  brands,
  buildings: {
    getAllFromOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<Building[]>(`/buildings/${organizationId}/all`),
    getByOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<Building[]>(`/workspaces/${organizationId}/all`),
    getById: (buildingId: IdentifierDTO) => () =>
      doGet<Building>(`/buildings/${buildingId}`),
    create: (building: Partial<Building>) =>
      doPost('/buildings/', building),
    delete: (buildingId: IdentifierDTO) =>
      doDelete(`/buildings/${buildingId}`),
    attachPhoto: (buildingId: IdentifierDTO) =>
      (photo: FormData) =>
        doPostFileUpload(`/buildings/${buildingId}/image`, photo),
    deletePhoto: (buildingId: IdentifierDTO) =>
      doDelete<Building>(`/buildings/${buildingId}/image`),
    updateSettings: (buildingId: IdentifierDTO) => (settings: Partial<Building>) =>
      doPut<Building>(`/buildings/${buildingId}/settings`, settings),
    update: (buildingId: IdentifierDTO) => (building: Partial<Building>) =>
      doPut(`/buildings/${buildingId}`, building),
    updateAcquisition: (buildingId: IdentifierDTO) => (acquisition: Partial<Building>) =>
      doPut(`/buildings/${buildingId}/acquisition`, acquisition),
    uploadPropertyManagerLogo: (buildingId: IdentifierDTO) =>
      (photo: FormData) =>
        doPostFileUpload(`/buildings/${buildingId}/acquisition/image`, photo),
    deletePropertyManagerLogo: (buildingId: IdentifierDTO) =>
      doDelete<Building>(`/buildings/${buildingId}/acquisition/image`),
    getRecent: (organizationId: IdentifierDTO) => () =>
      doGet<Building[]>(`/buildings/search/${organizationId}`),
    searchByName: (organizationID: IdentifierDTO) => (name: string) => () =>
      doGet<Building[]>(`/buildings/search/${organizationID}?name=${name}`)
  },
  calendar,
  checkout,
  cities,
  comments,
  contracts,
  copilot,
  countries: {
    get: () =>
      doGet<Country[]>('/countries')
  },
  currency: {
    getCurrencyByBuildingId: (buildingId: IdentifierDTO) =>
      doGet<{ _id: Currency }>(`/currencies/building/${buildingId}`),
    getCurrencyByFullAddress: (fullAddress) =>
      doGet<{ _id: Currency }>(`/currencies/address?fullAddress=${fullAddress}`),
    getCurrencyByOrganizationId: (organizationId?: IdentifierDTO) => () =>
      doGet<{ _id: Currency }>(`/currencies/organization/${organizationId}`)
  },
  dashboard,
  documentTemplate,
  documentUploadLinks,
  documents,
  drafts,
  expenses,
  incidents,
  investment,
  inventory: {
    getAllPaginatedByUnit: (unitId: IdentifierDTO) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Inventory[]>(toPaginatedURL(`/inventory/${unitId}`, pagination)),
    getAllByUnit: (unitId: IdentifierDTO) => () =>
      doGet<Inventory[]>(`/inventory/${unitId}`),
    get: (inventoryItemId: IdentifierDTO) => () =>
      doGet<Inventory>(`/inventory/item/${inventoryItemId}`),
    create: (unitId: IdentifierDTO, organizationId: IdentifierDTO) => (inventory: Partial<Inventory>) =>
      doPost<Inventory>(`/inventory/${unitId}`, { ...inventory, organizationId }),
    edit: (unitId: IdentifierDTO, organizationId: IdentifierDTO, inventoryItemId: IdentifierDTO) => (inventory: Partial<Inventory>) =>
      doPut<Inventory>(`/inventory/${unitId}/item/${inventoryItemId}`, { ...inventory, organizationId }),
    delete: (inventoryItemId: IdentifierDTO) =>
      doDelete<Inventory>(`/inventory/item/${inventoryItemId}`),
    patchBulkImages: () => (data: any) =>
      doPatchFileUpload<Inventory>(`/inventory/item/${data.inventoryItemId}/images`, data.formData)
  },
  invitations,
  invoices,
  keyDelivery,
  landings,
  messages: {
    getById: (messageId: IdentifierDTO) => () =>
      doGet<Message>(`/messages/${messageId}`),
    getMessages: (buildingId: IdentifierDTO) => () =>
      doGet<Message[]>(`/messages/buildings/${buildingId}`),
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Message[]>(toPaginatedURL(`/messages${params.url}`, pagination)),
    create: () => (message: Partial<Message>) =>
      doPost<Message>('/messages', message),
    attachPhoto: (messageId: IdentifierDTO) => (photo: FormData) =>
      doPutFileUpload(`/messages/${messageId}`, photo),
    send: (messageId: IdentifierDTO) => () =>
      doPost(`/messages/${messageId}`)
  },
  messaging,
  n43,
  nonPayments,
  notificationSettings,
  notifications,
  onboarding: {
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () => doGet<Application[]>(toPaginatedURL(`/onboarding${params.url}${params.queryParams}`, pagination)),
    getAllPaginated: (organizationId, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () => doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}${params.queryParams}`, pagination)),
    getLeadsPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/leads${params.queryParams}`, pagination)),
    getViewingsPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/state/viewings${params.queryParams}`, pagination)),
    getBackgroundChecksPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/state/backgroundCheck${params.queryParams}`, pagination)),
    getContractsPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/state/contractSigning${params.queryParams}`, pagination)),
    getMoveInsPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/state/moveIn${params.queryParams}`, pagination)),
    getLivingsPaginated: (organizationId: IdentifierDTO, params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        doGet<Application[]>(toPaginatedURL(`/onboarding/organization/${organizationId}/state/living${params.queryParams}`, pagination)),
    searchOnboardingTenants: (params: EndpointBuilder) => () =>
      doGet<Application[]>(`/onboarding${params.url}${params.queryParams}`),
    getByTenantId: (tenantId: IdentifierDTO) => () =>
      doGet<Application[]>(`/onboarding/tenant/${tenantId}`),
    getByTenantIdAndOrganization: (tenantId: IdentifierDTO, organizationId: IdentifierDTO) => () =>
      doGet<Application[]>(`/onboarding/tenant/${tenantId}/organization/${organizationId}`),
    getById: (onboardingId: IdentifierDTO) => () =>
      doGet<Application>(`/onboarding/${onboardingId}`),
    getPreScoringURL: (applicationId: IdentifierDTO) =>
      doGet<{ preScoringURL: string }>(`/onboarding/${applicationId}/pre-scoring-url`),
    sendWhatsAppPreScoringURL: (applicationId: IdentifierDTO) =>
      doPost(`/onboarding/${applicationId}/whatsapp-scoring-url`),
    inviteTenantOnboarding: () => (newTenantAndUnit: Partial<ApplicationRequestBody>) =>
      doPost('/onboarding/tenant/invite', newTenantAndUnit),
    attachFileToTenant: (tenantId) => (data) =>
      doPutFileUpload(`/onboarding/tenant/${tenantId.length ? tenantId : data.id}`, data.formData),
    updateUtility: (onboardingId: IdentifierDTO) => (utility: Partial<Utility>) =>
      doPut(`/onboarding/tenants/${onboardingId}/state/moveIn/utility/${utility.type}`, { utilityState: utility.state }),
    retrySendUtilitiesToProvider: (applicationId: IdentifierDTO) =>
      doPut(`/dashboard/application/${applicationId}/resend-provider-utilities`),
    getDocuments: (id: IdentifierDTO) => () =>
      doGet<Document[]>(`/onboarding/${id}/documents`),
    removeDocument: (documentId: IdentifierDTO) =>
      doDelete(`/documents/${documentId}`),
    replaceDocument: (data) =>
      doPutFileUpload(`/documents/${data.documentId}`, data.formData),
    decline: (applicationId: IdentifierDTO) => (body: DeclineApplicationRequest) =>
      doPut(`/onboarding/decline/${applicationId}`, body),
    skipStep: (applicationId: IdentifierDTO) => () =>
      doPut(`/onboarding/skip/${applicationId}`),
    skipOptionalStep: (applicationId: IdentifierDTO, state: ApplicationOptionalStateType) => () =>
      doPut(`/onboarding/skip/${applicationId}/state/${state}`),
    getArchivedPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () => doGet<Application[]>(toPaginatedURL(`/onboarding/archived${params.url}${params.queryParams}`, pagination)),
    deleteArchive: () => (applicationId: IdentifierDTO) =>
      doDelete(`/onboarding/archived/${applicationId}`),
    archive: (applicationId: IdentifierDTO) => () =>
      doPut(`/onboarding/archived/${applicationId}`),
    updateRentalConditions: (applicationId: IdentifierDTO) => (body: Partial<Application>) =>
      doPut(`/onboarding/conditions/${applicationId}`, body),
    sendHoldingDeposit: (applicationId: IdentifierDTO) => (body: SendHoldingDepositRequest) =>
      doPut<void>(`/onboarding/send-deposit/${applicationId}`, body),
    requestCommercialisationFee: (applicationId: IdentifierDTO) => (body: any) =>
      doPut(`/onboarding/request-commercialisation/${applicationId}`, body),
    requestTenantSearchServiceFee: (applicationId: IdentifierDTO) => (body: any) =>
      doPut(`/onboarding/request-tenant-search-service-fee/${applicationId}`, body),
    assignManager: (applicationId: IdentifierDTO) => (manager: Partial<User>) =>
      doPost(`/onboarding/${applicationId}/manager`, { managerId: manager._id }),
    unassignManager: (applicationId: IdentifierDTO) => (managerId: IdentifierDTO) =>
      doDelete(`/onboarding/${applicationId}/manager`, { managerId: managerId }),
    sendNote: (applicationId: IdentifierDTO) => (text: Partial<ApplicationComment>) =>
      doPost(`/onboarding/${applicationId}/comment`, { text }),
    getAllApplicationUnits: (params: EndpointBuilder) => () =>
      doGet<BasicUnit[]>(`/onboarding/units${params.url}${params.queryParams}`),
    getAllApplicationAgents: (params: EndpointBuilder) => () =>
      doGet<BasicUser[]>(`/onboarding/agents${params.url}${params.queryParams}`),
    getAllApplicationManagers: (params: EndpointBuilder) => () =>
      doGet<BasicUser[]>(`/onboarding/managers${params.url}${params.queryParams}`),
    createCommercialisationFeeDocument: (applicationId: IdentifierDTO) => (document: any) =>
      doPostFileUpload(`/onboarding/${applicationId}/commercialisation-fee-document`, document),
    editPreScoring: (applicationId: IdentifierDTO) => (preScoring: Partial<PreScoring>) =>
      doPut<any>(`/dashboard/application/pre-scoring/${applicationId}`, preScoring),
    editContactDetails: (applicationId: IdentifierDTO) => (contactDetails: Partial<ContactDetails>) =>
      doPut<any>(`/onboarding/contact-details/${applicationId}`, contactDetails),
    getTenantFromApplication: (applicationId: IdentifierDTO, tenantId: IdentifierDTO) => () =>
      doGet<Tenant>(`/onboarding/${applicationId}/tenants/${tenantId}`)
  },
  organizationIntegrationsSettings,
  organizations,
  ownerAccount,
  ownerLead: {
    getOwnerLeadPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<OwnerLead[]>(toPaginatedURL(`/owner-leads${params.url}`, pagination)),
    getById: (ownerLeadId: IdentifierDTO) => () =>
      doGet<OwnerLead>(`/owner-leads/${ownerLeadId}`),
    promote: (ownerLeadId: IdentifierDTO) => (data) =>
      doPost<OwnerAccount>(`/owner-leads/${ownerLeadId}/promote`, data),
    delete: () => (ownerLeadId: IdentifierDTO) =>
      doDelete(`/owner-leads/${ownerLeadId}/`)
  },
  ownerServiceContract,
  ownerTaxes,
  payments,
  payouts,
  portalIntegrations: {
    get: (organizationId: IdentifierDTO) => () =>
      doGet<PortalIntegration>(`/portal-integrations/${organizationId}`),
    getPortalsIntegrationUnitsPublishedStatus: (organizationId: IdentifierDTO) => () =>
      doGet<UnitsPublicationNotAvailableStatusInPortal>(`/portal-integrations/${organizationId}/units/publications/status`),
    createOrEdit: (organizationId: IdentifierDTO) => (body: Partial<PortalIntegration>) =>
      doPut<PortalIntegration>(`/portal-integrations/${organizationId}`, body),
    getActiveByUnit: (unitId: IdentifierDTO) => () =>
      doGet<PortalIntegrationTypes[]>(`/portal-integrations/unit/${unitId}`),
    updateUnitP2PPortals: (unitId: IdentifierDTO) => (operation: 'publish' | 'unpublish') =>
      doPut(`/portal-integrations/p2p-portals/${unitId}/${operation}`)
  },
  products: {
    get: (productId: IdentifierDTO) => () =>
      doGet<Product>(`/products/${productId}`),
    getAllAvailableForOwner: () => () =>
      doGet<Product[]>('/products/all/for-owner'),
    getAll: (serviceId: IdentifierDTO) => () =>
      doGet<Product[]>(`/v2/products/${serviceId}/all`),
    delete: (serviceId: IdentifierDTO) => (productId: IdentifierDTO) =>
      doDelete<Product>(`/products/${serviceId}/${productId}`),
    create: (serviceId: IdentifierDTO) => (product: Partial<Product>) =>
      doPost(`/products/${serviceId}`, product),
    edit: () => (product: Partial<Product>) =>
      doPut(`/products/${product._id}`, product),
    reorder: (serviceId: IdentifierDTO) => (product: Partial<Product>) =>
      doPut(`/products/${serviceId}/${product._id}/reorder`, { order: product.order }),
    duplicate: (serviceId: IdentifierDTO) => (product: Partial<Product>) =>
      doPut(`/products/${serviceId}/${product._id}/duplicate`),
    setPhoto: (serviceId: IdentifierDTO) => (productData: any) =>
      doPostFileUpload(`/products/${serviceId}/${productData._id}/photo`, productData.body),
    deletePhoto: (serviceId: IdentifierDTO) => (productId: IdentifierDTO) =>
      doDelete(`/products/${serviceId}/${productId}/photo`),
    hide: (serviceId: IdentifierDTO) => (product: Partial<Product>) =>
      doPut(`/products/${serviceId}/${product._id}/hide`),
    getCancellationPolicy: (serviceId: IdentifierDTO, productId: IdentifierDTO) => () =>
      doGet<CancellationPolicy>(`/products/${serviceId}/${productId}/policy`),
    addCancellationPolicy: (serviceId: IdentifierDTO) => (policy: Partial<CancellationPolicy>) =>
      doPost<CancellationPolicy>(`/products/${serviceId}/${policy.productId}/policy`, policy),
    editCancellationPolicy: (serviceId: IdentifierDTO, productId: IdentifierDTO) => (policy: Partial<CancellationPolicy>) =>
      doPut(`/products/${serviceId}/${productId}/policy`, policy),
    getPlaybook: (serviceId: IdentifierDTO, productId: IdentifierDTO) => () =>
      doGet<PlaybookTask[]>(`/products/${serviceId}/${productId}/playbook`),
    addPlaybookTask: (serviceId: IdentifierDTO, productId: IdentifierDTO) => (task: Partial<PlaybookTask>) =>
      doPost<PlaybookTask>(`/products/${serviceId}/${productId}/playbook`, task),
    createPlaybook: (serviceId: IdentifierDTO) => (playbook: Partial<PlaybookTask[]>, productId: IdentifierDTO) =>
      doPost<PlaybookTask>(`/products/${serviceId}/${productId}/new-playbook`, playbook),
    removePlaybookTask: (serviceId: IdentifierDTO, productId: IdentifierDTO) => (playbookTaskId: IdentifierDTO) =>
      doDelete(`/products/${serviceId}/${productId}/playbook/${playbookTaskId}`),
    editPlaybookTask: (serviceId: IdentifierDTO, productId: IdentifierDTO) => (task: Partial<PlaybookTask>) =>
      doPut<PlaybookTask>(`/products/${serviceId}/${productId}/playbook/${task._id}`, task),
    reorderPlaybook: (serviceId: IdentifierDTO, productId: IdentifierDTO) => (task: Partial<PlaybookTask>) =>
      doPut<PlaybookTask>(`/products/${serviceId}/${productId}/playbook/${task._id}/reorder`, { order: task.order })
  },
  providers,
  recurrentExpenses: {
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<RecurrentExpense[]>(toPaginatedURL(`/dashboard/recurrent-expenses${params.url}${params.queryParams}`, pagination)),
    getLinkablePaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<RecurrentExpense[]>(toPaginatedURL(`/dashboard/recurrent-expenses/linkable${params.url}${params.queryParams}`, pagination)),
    getOne: (id: IdentifierDTO) => () =>
      doGet<RecurrentExpense>(`/dashboard/recurrent-expenses/${id}`),
    getForecastedExpenses: (id: IdentifierDTO) => () =>
      doGet<ForecastedExpense[]>(`/dashboard/recurrent-expenses/${id}/forecasted-expenses`),
    create: (organizationId: IdentifierDTO) => (recurrentExpense) =>
      doPost<RecurrentExpense>(`/dashboard/recurrent-expenses/${organizationId}/create`, recurrentExpense),
    edit: (recurrentExpenseId: IdentifierDTO) => (editedRecurrentExpense: EditRecurrentExpenseInputs) =>
      doPut<RecurrentExpense>(`/dashboard/recurrent-expenses/${recurrentExpenseId}`, editedRecurrentExpense),
    addRecurrentDiscount: (recurrentExpenseId: IdentifierDTO) => (discount: AddRecurrentDiscountFormData) =>
      doPut<RecurrentExpense>(`/dashboard/recurrent-expenses/${recurrentExpenseId}/discount`, discount),
    removeRecurrentDiscount: (recurrentExpenseId: IdentifierDTO) => () =>
      doPut<RecurrentExpense>(`/dashboard/recurrent-expenses/${recurrentExpenseId}/remove-discount`),
    getRecurrentExpenseUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<BasicUnit[]>(`/dashboard/recurrent-expenses/units/organization/${organizationId}`),
    delete: (recurrentExpenseId: IdentifierDTO) =>
      doDelete(`/dashboard/recurrent-expenses/${recurrentExpenseId}`),
    getByLinkedId: (id: IdentifierDTO) => () =>
      doGet<RecurrentExpense[]>(`/dashboard/recurrent-expenses/${id}/linked`),
    linkRecurrentExpenses: (body: {}) =>
      doPut('/dashboard/recurrent-expenses/link', body),
    unlinkRecurrentExpense: (id) =>
      doPut(`/dashboard/recurrent-expenses/${id}/unlink`)
  },
  recurrentIncomes: {
    getOne: (recurrentIncomeId: IdentifierDTO) => () =>
      doGet<RecurrentIncome>(`/dashboard/recurrent-incomes/${recurrentIncomeId}`),
    getForecastedIncomes: (recurrentIncomeId: IdentifierDTO) => () =>
      doGet<ForecastedIncome[]>(`/dashboard/recurrent-incomes/${recurrentIncomeId}/forecasted-incomes`),
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<RecurrentIncome[]>(toPaginatedURL(`/dashboard/recurrent-incomes${params.url}${params.queryParams}`, pagination)),
    create: (organizationId: IdentifierDTO) => (recurrentIncome) =>
      doPost<RecurrentIncome>(`/dashboard/recurrent-incomes/${organizationId}/create`, recurrentIncome),
    edit: (recurrentIncomeId: IdentifierDTO) => (editedRecurrentIncome: EditRecurrentIncomeInputs) =>
      doPut<RecurrentIncome>(`/dashboard/recurrent-incomes/${recurrentIncomeId}`, editedRecurrentIncome),
    addRecurrentDiscount: (recurrentIncomeId: IdentifierDTO) => (discount: AddRecurrentDiscountFormData) =>
      doPut<RecurrentIncome>(`/dashboard/recurrent-incomes/${recurrentIncomeId}/discount`, discount),
    removeRecurrentDiscount: (recurrentIncomeId: IdentifierDTO) => () =>
      doPut<RecurrentIncome>(`/dashboard/recurrent-incomes/${recurrentIncomeId}/remove-discount`),
    getRecurrentIncomeUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<BasicUnit[]>(`/dashboard/recurrent-incomes/units/organization/${organizationId}`),
    delete: (recurrentIncomeId: IdentifierDTO) =>
      doDelete(`/dashboard/recurrent-incomes/${recurrentIncomeId}`),
    getOrganizationIBAN: (recurrentIncomeId: IdentifierDTO) => () =>
      doGet<string>(`/dashboard/recurrent-incomes/organization-iban/${recurrentIncomeId}`)
  },
  recurrentPayouts: {
    getOne: (id: IdentifierDTO) => () =>
      doGet<RecurrentPayout>(`/dashboard/recurrent-payouts/${id}`),
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<RecurrentPayout[]>(toPaginatedURL(`/dashboard/recurrent-payouts${params.url}${params.queryParams}`, pagination)),
    create: (organizationId: IdentifierDTO) => (recurrentIncome) =>
      doPost<RecurrentPayout>(`/dashboard/recurrent-payouts/${organizationId}/create`, recurrentIncome),
    getRecurrentPayoutUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<BasicUnit[]>(`/dashboard/recurrent-payouts/units/organization/${organizationId}`),
    delete: (recurrentPayoutId: IdentifierDTO) =>
      doDelete(`/dashboard/recurrent-payouts/${recurrentPayoutId}`)

  },
  reviews: {
    create: (body: Partial<Review>) =>
      doPost('/public/reviews', body)
  },
  search: {
    getToken: (signal?: AbortSignal) =>
      () =>
        doGet<SearchToken>('/search/token', undefined, undefined, { signal })
  },
  sepa: {
    getAllCreditors: (organizationId: IdentifierDTO) => () =>
      doGet<any[]>(`/sepa/creditors/${organizationId}`),
    getExportsPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Sepa[]>(toPaginatedURL(`/sepa${params.url}${params.queryParams}`, pagination)),
    uploadSepa: () => (sepaId) =>
      doPut<any>(`/sepa/${sepaId}`),
    deleteSepa: () => (sepaId: IdentifierDTO) =>
      doDelete(`/sepa/${sepaId}`)
  },
  sepaPayments,
  services: {
    getAll: () => () =>
      doGet<Service[]>('/services'),
    getAllAvailableForOwner: () => () =>
      doGet<Service[]>('/services/owner/available'),
    get: (serviceId: IdentifierDTO) => () =>
      doGet<any>(`/services/${serviceId}`),
    create: () => (service: Partial<Service>) =>
      doPost('/services', service),
    edit: (serviceId: IdentifierDTO) => (service: Partial<Service>) =>
      doPut<Service>(`/services/${serviceId}`, service),
    delete: (serviceId: IdentifierDTO) =>
      doDelete<Service>(`/services/${serviceId}`),
    setServicePhoto: () => (serviceData: any) =>
      doPostFileUpload(`/services/${serviceData._id}/photo`, serviceData.body),
    deleteServicePhoto: () => (serviceId: IdentifierDTO) =>
      doDelete(`/services/${serviceId}/photo`),
    createServiceHighlight: (serviceId: IdentifierDTO) => (highlight: Partial<ServiceHighlight>) =>
      doPost(`/services/${serviceId}/highlights`, highlight),
    deleteServiceHighlight: (serviceId: IdentifierDTO) => (highlightId: IdentifierDTO) =>
      doDelete<ServiceHighlight>(`/services/${serviceId}/highlights/${highlightId}`),
    getSubcategories: (serviceId: IdentifierDTO) => () =>
      doGet<Subcategory[]>(`/subcategories/${serviceId}/all`),
    createSubcategory: (serviceId: IdentifierDTO) => (name: Partial<Subcategory>) =>
      doPost<any>(`/subcategories/${serviceId}`, { title: name.title, hidden: false }),
    deleteSubcategory: (serviceId: IdentifierDTO) => (subcategoryId: IdentifierDTO) =>
      doDelete(`/subcategories/${serviceId}/${subcategoryId}`),
    updateSubcategory: (serviceId: IdentifierDTO) => (subcategory: Partial<Subcategory>) =>
      doPut<any>(`/subcategories/${serviceId}/${subcategory._id}`, { title: subcategory.title, hidden: subcategory.hidden }),
    reorderSubcategory: (serviceId: IdentifierDTO) => (subcategory: Partial<Subcategory>) =>
      doPut<Subcategory>(`/subcategories/${serviceId}/${subcategory._id}/reorder`, { order: subcategory.order }),
    reorderServices: () => (serviceToOrder: Partial<Service>) =>
      doPut(`/services/${serviceToOrder._id}/reorder`, { order: serviceToOrder.order }),
    hideSubcategory: (serviceId: IdentifierDTO) => (subcategory: Partial<Subcategory>) =>
      doPut<Subcategory>(`/subcategories/${serviceId}/${subcategory._id}/hide`),
    reorderOrganizationServices: (organizationId: IdentifierDTO) => (serviceToOrder: Partial<Service>) =>
      doPut(`/organizations/${organizationId}/service-reorder`, { order: serviceToOrder.order, serviceId: serviceToOrder._id })
  },
  taskTemplate,
  tasks,
  team: {
    searchByName: (name: string) => () =>
      doGet<User[]>(`/team/search?name=${name}`),
    searchActive: () =>
      doGet<User[]>('/team/active')
  },
  tenants: {
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Tenant[]>(toPaginatedURL(`/tenants${params.url}${params.queryParams}`, pagination)),
    getAll: () =>
      doGet<any[]>('/tenants'),
    get: (tenantId?: IdentifierDTO) => () =>
      doGet<Tenant>(`/tenants/${tenantId}`),
    getPreScoring: (tenantId: IdentifierDTO) => () =>
      doGet<any>(`/dashboard/tenant/pre-scoring/${tenantId}`),
    editPreScoring: (tenantId: IdentifierDTO) => (preScoring: Partial<PreScoring>) =>
      doPut<any>(`/dashboard/tenant/pre-scoring/${tenantId}`, preScoring),
    searchByName: (organizationId: IdentifierDTO) => (name: string) => () =>
      doGet<User[]>(`/tenants/${organizationId}/search?name=${name}`),
    searchInvitedTenantByName: (organizationId: IdentifierDTO) => (name: string) => () =>
      doGet<User[]>(`/tenants/${organizationId}/search/invited?name=${name}`),
    searchByNameInOrganization: (name: string) => () =>
      doGet<User[]>(`/tenants/search?name=${name}`),
    searchTenantByNameInOrganization: (organizationId: IdentifierDTO) => (name: string) => () =>
      doGet<User[]>(`/tenants/${organizationId}/search?name=${name}`),
    searchTenantByNameInOrganizationWithExclusions: (organizationId: IdentifierDTO, exclude: string) => (name: string) => () =>
      doGet<User[]>(`/tenants/${organizationId}/search?name=${name}&exclude=${exclude}`),
    searchTenantByNameInUnitOfOrganization: (organizationId: IdentifierDTO, unitId: IdentifierDTO) => (name: string) => () =>
      doGet<User[]>(`/tenants/${organizationId}/${unitId}/search?name=${name}`),
    assingTenantToUnit: (tenantId: IdentifierDTO) => (unit: Partial<Unit>) =>
      doPost(`/units/${unit._id}/tenant`, { tenantId }),
    unassingTenantFromUnit: (tenantId: IdentifierDTO) => (unitId: IdentifierDTO) =>
      doDelete(`/units/${unitId}/tenant`, { tenantId: tenantId }),
    create: (organizationId: IdentifierDTO) => (props) =>
      doPost(`/tenants/${organizationId}/new`, props.data),
    edit: (tenantId: IdentifierDTO) => (tenant: Partial<Tenant>) =>
      doPut<Tenant>(`/tenants/${tenantId}`, tenant),
    delete: (tenantId: IdentifierDTO) =>
      doDelete<Tenant>(`/tenants/${tenantId}`),
    invite: (tenantId: IdentifierDTO) =>
      doPost(`/tenants/${tenantId}/invite`),
    getDocuments: (tenantId: IdentifierDTO) => () =>
      doGet<Document[]>(`/tenants/documents/${tenantId}`),
    createDocument: (tenantId: IdentifierDTO) => (request: CreateDocumentRequest) =>
      doPostFileUpload<void>(`/tenants/documents/${tenantId}/`, request.build()),
    updateDocument: (tenantId: IdentifierDTO, documentId: IdentifierDTO) => (request: UpdateDocumentRequest) =>
      doPutFileUpload<void>(`/tenants/documents/${tenantId}/${documentId}`, request.build()),
    requestPlatformRating: (tenantId: IdentifierDTO) => () =>
      doPost<void>(`/tenants/${tenantId}/request-platform-rating`),
    requestGoogleReview: (tenantId: IdentifierDTO) => () =>
      doPost<void>(`/tenants/${tenantId}/request-google-review`)
  },
  units,
  unitsImporter: {
    import: (organizationId: IdentifierDTO) => (data: any) =>
      doPost<Unit>(`/import/units/${organizationId}`, data)
  },
  users: {
    getTeam: () =>
      doGet<MemberOfTeam[]>('/team'),
    getTeamPaginated: () =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<MemberOfTeam[]>(toPaginatedURL('/team', pagination)),
    updateTeamMember: (userId: IdentifierDTO) => (update: Partial<MemberOfTeam>) =>
      doPut<MemberOfTeam>(`/team/${userId}`, update),
    invite: (body) =>
      doPost('/team/invite', body),
    update: (body) =>
      doPut('/users', body),
    updateAvatar: (body: FormData) =>
      doPostFileUpload('/users/profile', body),
    deleteAvatar: () =>
      doDelete('/users/avatar'),
    deleteTeamMember: (userId: IdentifierDTO) =>
      doDelete(`/team/${userId}`),
    deleteZazumeUser: (userId: IdentifierDTO) =>
      doDelete(`/zazume/${userId}`),
    getZazumes: () =>
      doGet<Zazume[]>('/zazume'),
    getZazumePaginated: () =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Zazume[]>(toPaginatedURL('/zazume', pagination)),
    inviteZazume: (body) =>
      doPost('/zazume/invite', body),
    searchCalendarStaffByName: (name: string) => () =>
      doGet<User[]>(`/users/search/calendar-staff?name=${name}`),
    searchUserByNameInUnitOfOrganization: (organizationId: IdentifierDTO, unitId: IdentifierDTO) => (name: string) => () =>
      doGet<GenericUser[]>(`/users/${organizationId}/${unitId}/search?name=${name}`),
    searchIncidentsManangerByName: (name: string) => () =>
      doGet<GenericUser[]>(`/users/search/incidents-manager?name=${name}`),
    getUser: (userId: IdentifierDTO) => () =>
      doGet<User>(`/users/${userId}`),
    getBasicUser: (userId: IdentifierDTO) =>
      () =>
        doGet<BasicUser>(`/users/${userId}`)
  },
  userInformation,
  valuations: {
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) => () =>
        doGet<BasicPropertyValuation[]>(toPaginatedURL(`/dashboard/property-valuation${params.url}${params.queryParams}`, pagination)),

    getById: (valuationId: IdentifierDTO) => () =>
      doGet<PropertyValuation>(`/dashboard/property-valuation/${valuationId}`),
    sendValuationRequest: () => (body) =>
      doPost('/dashboard/property-valuation', body),
    updateValuationRequest: (valuationId: IdentifierDTO) => (body) =>
      doPut(`/dashboard/property-valuation/${valuationId}`, body),
    promoteToUnit: (valuationId: IdentifierDTO) => () =>
      doPost<PropertyValuation>(`/dashboard/property-valuation/promote/${valuationId}`),
    createReport: (valuationId: IdentifierDTO) => (reportBody) =>
      doPost<PropertyValuation>(`/dashboard/property-valuation/reports/${valuationId}`, reportBody),
    updateReport: (valuationId: IdentifierDTO) => (reportBody) =>
      doPut<PropertyValuation>(`/dashboard/property-valuation/reports/${valuationId}`, reportBody),
    removeReport: (valuationId: IdentifierDTO) =>
      doDelete(`/dashboard/property-valuation/reports/${valuationId}`),
    sendReport: (valuationId: IdentifierDTO) => (sendReportBody) =>
      doPost(`/dashboard/property-valuation/send-report/${valuationId}`, sendReportBody),
    delete: (valuationId: IdentifierDTO) =>
      doDelete(`/dashboard/property-valuation/${valuationId}`)
  },
  viewingRating: {
    sendViewingRating: (applicationId: IdentifierDTO) => (data): Promise<any> =>
      doPut(`/public/reviews/viewing-rating/${applicationId}/rating`, data),
    sendAgentViewingFeedback: (token: string, viewingId) => (data) =>
      doPut(`/public/reviews/viewing-rating/${viewingId}/agent-feedback?token=${token}`, data),
    findPendingViewingsForAgentFeedback: (token: string) => () =>
      doGet<Viewing[]>(`/public/reviews/viewing-rating/viewings?token=${token}`)
  },
  viewings,
  workspaces: {
    getAllFromOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<Workspace[]>(`/workspaces/${organizationId}/all`),
    create: (workspace: Partial<Workspace>) =>
      doPost('/workspaces', workspace),
    update: (workspaceId: IdentifierDTO) => (workspace: Partial<Workspace>) =>
      doPut(`/workspaces/${workspaceId}`, workspace),
    delete: (workspaceId: IdentifierDTO) =>
      doDelete(`/workspaces/${workspaceId}`)
  },
  zazu: {
    searchZazu: () =>
      doGet<User[]>('/zazume/all'),
    searchZazuByName: (name: string) => () =>
      doGet<Building[]>(`/zazume/search?name=${name}`)
  }
};
