import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { EventType } from './events/events';
import { Config, isSegmentEnabled } from '../../lib/Config';
import { UserInformation } from '#/models/UserInformation';
import { UserInformationHelper } from '#/models/helpers/userInformation/UserInformationHelper';

export default class Segment {
  private isEnabled: boolean;
  private isInitialized: boolean;
  private userInformation: UserInformation | undefined;
  private analyticsModule?: Analytics;

  constructor() {
    this.isInitialized = false;
    this.isEnabled = isSegmentEnabled();
  }

  start() {
    /* We don't initialize segment because we don't have userInformation */
  }

  async setUser(userInformation: UserInformation) {
    this.calculateEnabled(userInformation);
    if (!this.isEnabled) {
      return;
    }

    await this.initialize();
    this.userInformation = userInformation;
    this.analyticsModule?.identify(userInformation._id, userInformation);
  }

  private calculateEnabled(userInformation: UserInformation) {
    const userInformationHelper = new UserInformationHelper(userInformation);
    this.isEnabled = isSegmentEnabled() && (userInformationHelper.isOwner() || userInformationHelper.isAgent());
  }

  private async initialize() {
    if (!this.isEnabled) {
      return;
    }
    if (this.isInitialized) {
      return;
    }
    try {
      const [response] = await AnalyticsBrowser.load({
        cdnURL: 'https://analytics.zazume.com',
        writeKey: Config.SEGMENT_KEY
      });
      this.isInitialized = true;
      this.analyticsModule = response;
    } catch (err) {
      console.log(err);
    }
  }

  trackPageView(pageName: string) {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    this.analyticsModule?.page({ page: pageName }, undefined, undefined, { email: this.userInformation?.email });
  }

  trackEvent(eventName: EventType, event?: Object) {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    this.analyticsModule?.track(eventName, event, { email: this.userInformation?.email });
  }

  clear() {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    this.analyticsModule?.reset();
    this.isInitialized = false;
  }
}
