import { isGoogleAnalyticsEnabled } from '../../lib/Config';
import ReactGA from 'react-ga4';
import { UserInformation } from '#/models/UserInformation';
import { UserInformationHelper } from '#/models/helpers/userInformation/UserInformationHelper';


export class GoogleAnalytics {
  private isEnabled: boolean;
  private isInitialized: boolean;

  constructor() {
    this.isInitialized = false;
    this.isEnabled = isGoogleAnalyticsEnabled();
  }

  start() {
    /* We don't initialize analytics because we don't have userInformation */
  }

  private initialize() {
    if (!this.isEnabled) {
      return;
    }
    if (this.isInitialized) {
      return;
    }
    ReactGA.initialize('G-MFKDTKF48B');
    this.isInitialized = true;
  }

  clear() {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    ReactGA.reset();
    this.isInitialized = false;
  }

  setUser(userInformation: UserInformation) {
    this.calculateEnabled(userInformation);
    if (!this.isEnabled) {
      return;
    }

    this.initialize();
    ReactGA.set({ 'userId': userInformation._id });

    const userInformationHelper = new UserInformationHelper(userInformation);
    if (userInformationHelper.isOwner()) {
      ReactGA.set({ 'role': 'owner' });
    }
  }

  sendPageView(path: string) {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    ReactGA.send({ hitType: 'pageview', page: path });
  }

  private calculateEnabled(userInformation: UserInformation) {
    const userInformationHelper = new UserInformationHelper(userInformation);
    this.isEnabled = isGoogleAnalyticsEnabled() && userInformationHelper.isOwner();
  }
}
