import { IdentifierDTO } from '@zazume/zzm-base';
import { Signer } from './contract/Signer';

export interface Document {
  _id: IdentifierDTO;
  filename: string;
  size: number;
  fileKey: string;
  type: DocumentType;
  author: IdentifierDTO;
  relatedModel: RelatedModel;
  expireAt?: Date;
  state?: DocumentState;
  signatureExternalId?: string;
  signers?: Signer[];
  rejected?: DocumentRejected;
  isHiddenFromOwner?: boolean;
  hasSignatureError?: boolean;
}

export const DocumentStates = <const>['draft', 'rejected', 'signed', 'expired_signature'];
export type DocumentState = typeof DocumentStates[number];

export const RelatedModels = <const>[
  'Agent',
  'Contract',
  'Guarantor',
  'Incident',
  'Provider',
  'Sharer',
  'Unit',
  'User',
  'OwnerAccount',
  'OwnerServiceContract',
  'NonPayment'
];

export type RelatedModelType = typeof RelatedModels[number];

export const ContextModels = <const>[
  'Application' // AKA OnBoarding
];

export type ContextType = typeof ContextModels[number];

export interface SimpleRelatedModel {
  id: IdentifierDTO;
  name: RelatedModelType;
}

export interface RelatedModelContext {
  contextType: ContextType;
  contextId: IdentifierDTO;
}

export type RelatedModel = SimpleRelatedModel & Partial<RelatedModelContext>;

export const InventoryDocumentTypes = <const>['inventory', 'contraInventory'];
export type InventoryDocumentType = typeof InventoryDocumentTypes[number];

export const ProviderDocumentCategories = <const>['provider'];
export type ProviderDocumentCategoriesType = typeof ProviderDocumentCategories[number];

export const ProviderDocumentTypes = <const>['provider'];
export type ProviderDocumentType = typeof ProviderDocumentTypes[number];

export const AgentDocumentCategories = <const>['agent'];
export type AgentDocumentCategoriesType = typeof AgentDocumentCategories[number];

export const AgentDocumentTypes = <const>['agent', 'viewingsContract', 'partnershipContract', 'otherContract'];
export type AgentDocumentType = typeof AgentDocumentTypes[number];

export const OwnerAccountDocumentCategories = <const>['idCards', 'others'];
export type OwnerAccountCategoriesType = typeof OwnerAccountDocumentCategories[number];

export const OwnerServiceContractCategories = <const>['ownerServiceContract', 'ownerServiceCommercialisationContract', 'ownerServiceManagementContract', 'other'];
export type OwnerServiceContractCategoriesType = typeof OwnerServiceContractCategories[number];

export const NonPaymentCategories = <const>['nonPayment'];
export type NonPaymentCategoriesType = typeof NonPaymentCategories[number];

export const OwnerAccountDocumentTypes = <const>['ownerAccount'];
export type OwnerAccountDocumentType = typeof AgentDocumentTypes[number];

export const InsuranceDocumentTypes = <const>['homeInsurance', 'otherInsurance'];
export type InsuranceDocumentType = typeof InsuranceDocumentTypes[number];

export const OwnerServiceContractTypes = <const>['ownerServiceContract_contract', 'other'];
export type OwnerServiceContractType = typeof OwnerServiceContractTypes[number];

export const OwnerServiceCommercialisationContractTypes = <const>['ownerServiceContract_commercialisationContract', 'other'];
export type OwnerServiceCommercialisationContractType = typeof OwnerServiceCommercialisationContractTypes[number];

export const OwnerServiceCommercialisationContractDocumentTypes = <const>['ownerServiceContract_commercialisationContract'];
export type OwnerServiceCommercialisationContractDocumentType = typeof OwnerServiceCommercialisationContractDocumentTypes[number];

export const OwnerServiceManagementContractTypes = <const>['ownerServiceContract_managementContract', 'other'];
export type OwnerServiceManagementContractType = typeof OwnerServiceManagementContractTypes[number];

export const OwnerServiceManagementContractDocumentTypes = <const>['ownerServiceContract_managementContract'];
export type OwnerServiceManagementContractDocumentType = typeof OwnerServiceManagementContractDocumentTypes[number];

export const IncidentDocumentCategories = <const>['incident'];
export type IncidentDocumentCategoriesType = typeof IncidentDocumentCategories[number];

export const IncidentDocumentTypes = <const>['budget', 'other'];
export type IncidentDocumentType = typeof IncidentDocumentTypes[number];

export const NonPaymentDocumentTypes = <const>['severance', 'powerOfAttorney', 'other'];
export type NonPaymentDocumentType = typeof NonPaymentDocumentTypes[number];

export const DocumentTypes = <const>[
  'internetConnectionInvoice',
  'waterSupplyInvoice',
  'electricityCupsCIEInvoice',
  'gasCupsInvoice',
  'holdingDepositContract',
  'occupancyCertificate',
  'energyPerformanceCertificate',
  'cadastralReport',
  'idFront',
  'idBack',
  'passport',
  'payslip',
  'pensionForm',
  'workingLifeReport',
  'employmentContract',
  'taxForm100',
  'freelanceInvoices',
  'signedContract',
  'unsignedContract',
  'remodelingGeneral',
  'rentReceipt',
  'insuranceContract',
  'sepa',
  'sepaMandate',
  'other',
  'payoutReceipt',
  'signedContractAttachment',
  'unsignedContractAttachment',
  'invoice',
  'commercialisationFeeContract',
  ...InventoryDocumentTypes,
  ...ProviderDocumentTypes,
  ...AgentDocumentTypes,
  ...InsuranceDocumentTypes,
  ...IncidentDocumentTypes,
  ...OwnerAccountDocumentTypes,
  ...OwnerServiceContractTypes,
  ...OwnerServiceCommercialisationContractTypes,
  ...OwnerServiceManagementContractTypes,
  ...NonPaymentDocumentTypes
];
export type DocumentType = typeof DocumentTypes[number];

export type DocumentCategories =
  ProviderDocumentCategoriesType |
  UnitDocumentCategoriesType |
  TenantDocumentCategoriesType |
  AgentDocumentCategoriesType |
  IncidentDocumentCategoriesType |
  OwnerAccountCategoriesType |
  OwnerServiceContractCategoriesType |
  NonPaymentCategoriesType;

export const UserDocumentTypes = <const>['internetConnectionInvoice'];
export const UnitDocumentTypes = <const>['waterSupplyInvoice', 'electricityCupsCIEInvoice', 'gasCupsInvoice', 'other'];

export const UnitDocumentCategories = <const>['invoices', 'building', 'remodeling', 'other', 'inventory', 'insurance'];
export type UnitDocumentCategoriesType = typeof UnitDocumentCategories[number];

export const UnitInvoicesDocumentTypes = <const>['waterSupplyInvoice', 'electricityCupsCIEInvoice', 'gasCupsInvoice'];
export type UnitInvoicesDocumentType = typeof UnitInvoicesDocumentTypes[number];

export const BuildingDocumentTypes = <const>['occupancyCertificate', 'energyPerformanceCertificate', 'cadastralReport'];
export type BuildingDocumentType = typeof BuildingDocumentTypes[number];

export const RemodelingDocumentTypes = <const>['remodelingGeneral'];
export type RemodelingDocumentType = typeof RemodelingDocumentTypes[number];

export const findUnitDocumentCategory = (type: any) => {
  if (InventoryDocumentTypes.includes(type)) {
    return 'inventory';
  }
  if (type === 'other') {
    return 'other';
  }
  if (UnitInvoicesDocumentTypes.includes(type)) {
    return 'invoices';
  }
  if (BuildingDocumentTypes.includes(type)) {
    return 'building';
  }
  if (RemodelingDocumentTypes.includes(type)) {
    return 'remodeling';
  }
  if (InsuranceDocumentTypes.includes(type)) {
    return 'insurance';
  }

  if (OwnerServiceCommercialisationContractDocumentTypes.includes(type)) {
    return 'ownerServiceCommercialisationContract';
  }

  if (OwnerServiceManagementContractDocumentTypes.includes(type)) {
    return 'ownerServiceManagementContract';
  }
};

export const TenantDocumentCategories = <const>[
  'backgroundCheck',
  'holdingDepositContract',
  'idCards',
  'insurance',
  'invoices',
  'otherContractDocuments',
  'others',
  'payments',
  'rentalInsurance'
];
export type TenantDocumentCategoriesType = typeof TenantDocumentCategories[number];

export const TenantInvoicesDocumentTypes = <const>['internetConnectionInvoice'];
export type TenantInvoicesDocumentType = typeof TenantInvoicesDocumentTypes[number];

export const IdCardsDocumentTypes = <const>['idFront', 'idBack', 'passport'];
export type IdCardsDocumentType = typeof IdCardsDocumentTypes[number];

export const BackgroundCheckDocumentTypes = <const>['payslip', 'employmentContract', 'pensionForm', 'taxForm100', 'taxForm200', 'freelanceInvoices', 'workingLifeReport'];
export type BackgroundCheckDocumentType = typeof BackgroundCheckDocumentTypes[number];

export const InsuranceContractDocumentTypes = <const>['insuranceContract'];
export type InsuranceContractDocumentType = typeof InsuranceContractDocumentTypes[number];

export const PaymentsDocumentTypes = <const>['sepaMandate'];
export type PaymentsDocumentType = typeof PaymentsDocumentTypes[number];

export const OtherContractDocumentTypes = <const>['commercialisationFeeContract'];
export type OtherContractDocumentType = typeof PaymentsDocumentTypes[number];

export const OtherDocumentTypes = <const>['other'];
export type OtherDocumentType = typeof OtherDocumentTypes[number];


export const findTenantDocumentCategory = (type: any, contextType?: ContextType) => {
  if (TenantInvoicesDocumentTypes.includes(type)) {
    return 'invoices';
  }
  if (IdCardsDocumentTypes.includes(type)) {
    return 'idCards';
  }
  if (BackgroundCheckDocumentTypes.includes(type)) {
    return 'backgroundCheck';
  }
  const isOpenBackgroundCheck = type === 'other' && contextType === 'Application';
  if (isOpenBackgroundCheck) {
    return 'backgroundCheck';
  }
  if (InsuranceContractDocumentTypes.includes(type)) {
    return 'rentalInsurance';
  }
  if (PaymentsDocumentTypes.includes(type)) {
    return 'payments';
  }
  if (OtherContractDocumentTypes.includes(type)) {
    return 'otherContractDocuments';
  }
  if (InsuranceDocumentTypes.includes(type)) {
    return 'insurance';
  }
  if (type === 'holdingDepositContract') {
    return 'holdingDepositContract';
  }
  if (OtherDocumentTypes.includes(type) && !isOpenBackgroundCheck) {
    return 'others';
  }
};

export interface DocumentRejected {
  name: string;
  email: string;
  description: string;
}
