import { useI18n } from '#hooks/useI18n';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC, Regular, ZazumeModal } from '@zazume/zzm-base';
import React from 'react';

const Body = styled.div(({ theme }) => css`
  padding: ${theme.spacing.md} 0;
`);

export interface GenericDeleteConfirmationModalProps {
  onConfirm: () => Promise<void> | void;
  onCancel: () => void;
  title: string;
  description?: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  isSubmitting?: boolean;
}

// TODO: move to base
export const GenericDeleteConfirmationModal: FCC<GenericDeleteConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  title,
  description,
  actionButtonText,
  cancelButtonText,
  isSubmitting,
  children
}) => {

  const { t } = useI18n();

  const cancelText = cancelButtonText ? cancelButtonText : t('buttons.cancel');
  const confirmText = actionButtonText ? actionButtonText : t('general.delete');

  return (
    <ZazumeModal title={title} onClose={onCancel} withCloseButton={false}>
      <Body>
        {description && <Regular variant="S" color="Gray500">{description}</Regular>}
        {children}
      </Body>
      <ZazumeModal.Footer onConfirm={onConfirm} onCancel={onCancel} acceptButtonText={confirmText} cancelButtonText={cancelText} isSubmitting={isSubmitting} variant="decline"/>
    </ZazumeModal>
  );
};
