import { isHotjarEnabled } from '../../lib/Config';
import { hotjar } from 'react-hotjar';
import { UserInformation } from '#/models/UserInformation';


export default class Hotjar {
  private isEnabled: boolean;
  private isInitialized: boolean;

  constructor() {
    this.isInitialized = false;
    this.isEnabled = isHotjarEnabled();
  }

  start() {
    this.initialize();
  }

  initialize() {
    if (!this.isEnabled) {
      return;
    }
    if (this.isInitialized) {
      return;
    }
    hotjar.initialize(2537147, 6);
    this.isInitialized = true;
  }

  setUser(userInformation: UserInformation) {
    if (!this.isEnabled) {
      return;
    }
    if (!this.isInitialized) {
      return;
    }
    hotjar.identify(userInformation._id, { email: userInformation.email });
  }
}
