import { UserInformation, UserInformationRole } from '#/models/UserInformation';

export class UserInformationHelper {
  private readonly userInformation: UserInformation;

  constructor(userInformation: UserInformation) {
    this.userInformation = userInformation;
  }

  hasRole(role: UserInformationRole): boolean {
    return this.userInformation.roles.includes(role);
  }

  isOwner() {
    return this.hasRole('owner');
  }

  isZazume() {
    return this.hasRole('zazume');
  }

  isAgent() {
    return this.hasRole('agent');
  }
}
