import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useI18n } from '#/hooks/useI18n';
import { Document, DocumentCategories, RelatedModelType } from '../../models';
import { Flex, getIcon, IconType, IdentifierDTO, Plus, SecondaryButton, Semibold } from '@zazume/zzm-base';
import { DocumentCard } from './DocumentCard';
import { css } from '@emotion/react';
import { EmptyState } from '../other/EmptyState';
import { DropdownMenu } from './atoms/DropdownMenu';
import { Can } from '../roles/Can';
import MetricsHelper from '#/utils/metrics/metricsHelper';

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`;

const Top = styled.div(({ theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${theme.mq.sm} {
    flex-direction: row;
    align-items: center;
  }
`);

const TopAction = styled.div(({ theme }) => css`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  white-space: nowrap;
`);

const DocumentsContainer = styled.div(({ theme }) => css`
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 20px;
  padding-top: 20px;
  min-height: 77px;

  ${theme.mq.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`);

export interface DocumentUploaderProps {
  documents: Document[];
  icon: IconType;
  title: string;
  onUpdate?: (document: Document) => void;
  onRemove?: (document: Document) => void;
  onUpload?: () => void;
  buttonSize?: 'Medium' | 'Large';
  relatedModel: RelatedModelType;
  relatedModelId: IdentifierDTO;
  category: DocumentCategories;
}

export const DocumentUploader: FC<DocumentUploaderProps> = ({ icon, title, documents, onUpload, onRemove, onUpdate, buttonSize = 'Medium', category, relatedModel, relatedModelId }) => {
  const { t } = useI18n();
  const Icon = getIcon(icon);

  const isEditable = Boolean(onUpload);

  const handleUpload = () => {
    MetricsHelper.track('Upload Document Btn', { category });
    onUpload?.();
  };

  return (
    <Container>
      <Top>
        <Flex align="center" gap="xs">
          <Icon color="Primary" size={32}/>
          <Semibold variant="XL">{title}</Semibold>
        </Flex>
        {isEditable &&
          <TopAction>
            <SecondaryButton size={buttonSize} onClick={handleUpload}><Plus color="Primary" size={16}/> {t('documents.newDocument')}</SecondaryButton>
            <Can hasRoles={['admin', 'buildingManager']}>
              <DropdownMenu relatedModel={relatedModel} relatedModelId={relatedModelId} category={category}/>
            </Can>
          </TopAction>}
      </Top>
      {!documents.length ? (
        isEditable
          ? <EmptyState text={t('documents.emptyState')} linkText={t('general.clickHere')} onClick={onUpload}/>
          : <EmptyState text={t('documents.emptyStateNotEditable')}/>
      ) : (
        <DocumentsContainer>
          {documents.map(document => <DocumentCard documentId={document._id} onRemove={onRemove} onUpdate={onUpdate} key={document._id}/>)}
        </DocumentsContainer>
      )}
    </Container>
  );
};
