import React, { useCallback, useContext, useEffect, useState } from 'react';
import { API } from '../lib/network/API';
import { AuthOrganization, AuthResponse, isOwnerUser, isZazumeUser, ModelId, Organization, User, UserRolesTypes } from '../models';
import MetricsHelper from '../utils/metrics/metricsHelper';
import { useNavigate } from 'react-router-dom';
import { Anonymous } from '../containers/router/routes/Anonymous';
import { arrayIsNotEmpty } from '../utils/arraysHelper';
import { Private } from '../containers/router/routes/Private';
import { useQueryClient } from 'react-query';
import { IdentifierDTO } from '@zazume/zzm-base';
import { Storage } from '../utils/Storage';
import { VersionedStorage } from '#/utils/storage/versionedStorage';

export const USER_STORAGE_KEY = 'user';
export const USER_STORAGE_VERSION = '1.0.0';

export const ORGANIZATION_STORAGE_KEY = 'organization';
export const ORGANIZATION_STORAGE_VERSION = '1.0.0';

export interface UseUserResult {
  user?: User;
  organization?: AuthOrganization;
  role?: UserRolesTypes,
  isLogged: () => boolean;
  isOwner: boolean;
  isBlacklisted: () => boolean;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  refetch: () => Promise<void>;
  changeOrganization: (id: IdentifierDTO) => Promise<void>;
  loginWithSingleSignOn: (authResponse: AuthResponse, redirectOnLogin?: string) => Promise<void>;
}

const calculateRolZazumeCurrentOrganization = async (organizationId: IdentifierDTO): Promise<Organization> => {
  const organizations = await API.organizations.getAll();
  const newOrganization = organizations.find(organization => organization._id === organizationId);
  if (newOrganization) {
    return newOrganization;
  }
  return organizations[0];
};

const calculateRolOwnerCurrentOrganization = async (organizationId: IdentifierDTO): Promise<Organization> => {
  const ownerOrganizations = await API.ownerAccount.getOwnerAccountOrganizations()();
  const ownerOrganizationFound = ownerOrganizations.find(ownerOrganization => ownerOrganization._id === organizationId);
  if (!ownerOrganizationFound) {
    throw new Error(`No organization received after querying for organization ${organizationId}`);
  }
  return ownerOrganizationFound;
};


// TODO Create reducer for User like UserHelper in backend. Tell don't ask.
const LoginContext = React.createContext<UseUserResult>({
  user: undefined,
  organization: undefined,
  role: undefined,
  isLogged: () => {
    return false;
  },
  isOwner: false,
  isBlacklisted: () => {
    return false;
  },
  logout: async () => {
  },
  login: async (email: string, password: string) => {
  },
  refetch: async () => {
  },
  changeOrganization: async (id: ModelId) => {
  },
  loginWithSingleSignOn: async () => {
  }
});

const extractUser = (): User | undefined => {
  return VersionedStorage.getItem<User>(USER_STORAGE_KEY, USER_STORAGE_VERSION) || undefined;
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const extractOrganization = (): AuthOrganization | undefined => {
    const organization = VersionedStorage.getItem<AuthOrganization>(ORGANIZATION_STORAGE_KEY, ORGANIZATION_STORAGE_VERSION);
    if (organization) {
      return organization;
    }
  };

  const getOwnerRole = (user: User | undefined, organization: Organization | undefined): UserRolesTypes => {
    if (user && organization) {
      const organizationId = organization._id;
      for (const ownerRole of user.roles) {
        const destructuredRole = ownerRole.split(':');
        if (arrayIsNotEmpty(destructuredRole) && destructuredRole.length === 2 && destructuredRole[1] === organizationId) {
          return destructuredRole[0] as UserRolesTypes;
        }
      }
    }
    return 'ownerBasic';
  };

  const [user, setUser] = useState<User | undefined>(extractUser());
  const [organization, setOrganization] = useState<AuthOrganization | undefined>(extractOrganization());
  const [role, setRole] = useState<UserRolesTypes | undefined>(getOwnerRole(user, organization));

  /**
   * @deprecated use the authToken instead
   * @param authResponse
   */
  const setAuthUserAndOrganization = async (authResponse: AuthResponse, redirectOnLogin?: string): Promise<void> => {
    const { user, organization } = authResponse;
    const { organizationId, token, isDefaultOrganization } = organization;

    VersionedStorage.setItem(USER_STORAGE_KEY, user, USER_STORAGE_VERSION);
    MetricsHelper.identifyUser();
    await checkForOrganization(user, organizationId, isDefaultOrganization);
    setUser(user);
    setCurrentOrganization(token);
    if (redirectOnLogin) {
      navigate(redirectOnLogin, { replace: true });
    }
    navigate(Private.HOME.route, { replace: true });
    window['isLoggingOut'] = undefined;
  };

  const login = async (email, password) => {
    const response = await API.auth.login(email, password);
    Storage.setAuthToken(response.authToken);
    await setAuthUserAndOrganization(response);
  };

  const loginWithSingleSignOn = async (authResponse: AuthResponse, redirectOnLogin?: string): Promise<void> => {
    await setAuthUserAndOrganization(authResponse, redirectOnLogin);
  };

  const logout = useCallback(async () => {
    if (window['isLoggingOut']) {
      return;
    }
    window['isLoggingOut'] = true;
    await queryClient.cancelQueries();
    await API.auth.logout();
    MetricsHelper.clearUser();
    Storage.clearSession();
    setUser(undefined);
    setOrganization(undefined);
    queryClient.clear();
  }, [queryClient]);

  const refetch = async () => {
    const user: User = await API.auth.profile();
    VersionedStorage.setItem(USER_STORAGE_KEY, user, USER_STORAGE_VERSION);
    setUser(user);
  };

  const isLogged = (): boolean => {
    return user !== undefined;
  };

  const isBlacklisted = (): boolean => {
    //TODO: ZZM-2784 we hide all commercialization features when agents are coming from IAD
    return Boolean(user?.email.includes('@iadespana.es'))
      || Boolean(user?.email.includes('@iadgroup.es'))
      || Boolean(user?.email.includes('hectormorillo023+zazumeagent@gmail.com'));
  };

  const changeOrganization = async (id: string) => {
    const { token, isDefaultOrganization } = await API.auth.getCurrentOrganizationToken(id);
    setCurrentOrganization(token);
    const organization = await API.organizations.get(id)();
    VersionedStorage.setItem(
      ORGANIZATION_STORAGE_KEY,
      { ...organization, isDefaultOrganization },
      ORGANIZATION_STORAGE_VERSION
    );
    setOrganization({ ...organization, isDefaultOrganization });
    if (user && isOwnerUser(user)) {
      await checkForOrganization(user, id, isDefaultOrganization);
    }
  };

  const setCurrentOrganization = (organizationToken: string) => {
    if (organizationToken) {
      localStorage.setItem('currentOrganizationToken', organizationToken);
    }
  };

  const checkForOrganization = async (user: User, organizationId: IdentifierDTO, isDefaultOrganization: boolean) => {
    let organization;

    if (isZazumeUser(user)) {
      organization = await calculateRolZazumeCurrentOrganization(organizationId);
    } else if (isOwnerUser(user)) {
      if (!user.owner?.ownerAccountId) {
        console.error('Missing Owner Account Id for Owner user');
        await logout();
        return;
      }
      organization = await calculateRolOwnerCurrentOrganization(organizationId);
      const ownerRole = getOwnerRole(user, organization);
      setRole(ownerRole);
    } else {
      organization = await API.organizations.get(user.organization)();
    }

    const authOrganization = { ...organization, isDefaultOrganization };
    VersionedStorage.setItem(ORGANIZATION_STORAGE_KEY, authOrganization, ORGANIZATION_STORAGE_VERSION);
    setOrganization(authOrganization);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const user = extractUser();
      if (!user) {
        await logout();
        return;
      }
      setUser(user);
      MetricsHelper.identifyUser();
    })();
  }, [logout]);

  return (
    <LoginContext.Provider value={{
      user,
      organization,
      role,
      isLogged,
      isOwner: isOwnerUser(user),
      isBlacklisted,
      login,
      logout,
      refetch,
      changeOrganization,
      loginWithSingleSignOn
    }}>
      {children}
    </LoginContext.Provider>);
};

export const useAuth = (): UseUserResult => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const useCurrentOrganization = (): IdentifierDTO => {
  const { organization } = useAuth();
  if (!organization) {
    throw new Error('useCurrentOrganization must be used in a private route');
  }
  return organization._id;
};

export const useIsDefaultOrganization = (): boolean => {
  const { organization } = useAuth();
  if (!organization) {
    throw new Error('useIsDefaultOrganization must be used in a private route');
  }
  return organization.isDefaultOrganization;
};

/**
 * @deprecated use useSession
 */
export const useCurrentUser = (): User => {
  const { user } = useAuth();
  if (!user) {
    throw new Error('useCurrentUser must be used in a private route');
  }
  return user;
};

/**
 * @deprecated Use useSession
 */
export const useCurrentRole = (): UserRolesTypes => {
  const { role } = useAuth();
  if (!role) {
    throw new Error('useCurrentRole must be used in a private route');
  }
  return role;
};
