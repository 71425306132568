import { PaginationOptions } from '#/hooks';
import { BasicUnit, Document } from '#/models';
import { BeneficiaryModel, Expense } from '#/models/Expense';
import { Payer } from '#/models/Payment';
import { ExportInvoicesInputs } from '#/pages/accounting/invoices/components/ExportInvoices.schema';
import { doDelete, doGet, doPatchFileUpload, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';
import { BulkCreateReconciledExpenseRequest } from './requests/BulkCreateReconciledExpenseRequest';

export const expenses = {
  addAttachments: (expenseId: IdentifierDTO) => (document: any) =>
    doPatchFileUpload(`/dashboard/expenses/attachments/${expenseId}`, document),
  addExpense: (organizationId: IdentifierDTO) => (expense: any) =>
    doPostFileUpload<any>(`/dashboard/expenses/${organizationId}`, expense),
  addExpenseFromBankTransaction: (organizationId: IdentifierDTO, transactionId: IdentifierDTO) => (expense: any) =>
    doPostFileUpload(`/dashboard/expenses/${organizationId}/transaction/${transactionId}`, expense),
  addMultiplePayments: () => (newReconciliations) =>
    doPut('/dashboard/expenses/multiple-payments', newReconciliations),
  addMultiplePaymentsFromBankTransactions: (expenseId: IdentifierDTO) => (formData: any) =>
    doPut(`/dashboard/expenses/payment/${expenseId}/bank-transactions`, formData),
  addPayment: (expenseId: IdentifierDTO) => (formData: any) =>
    doPutFileUpload(`/dashboard/expenses/payment/${expenseId}`, formData),
  bulk: {
    createReconciled: (request: BulkCreateReconciledExpenseRequest) =>
      doPost<void>('/dashboard/expenses/bulk/reconcile', request)
  },
  delete: (expenseId: IdentifierDTO) => (formData: any) =>
    doDelete(`/dashboard/expenses/${expenseId}`, formData),
  editExpense: (expenseId: IdentifierDTO) => (expense: FormData) =>
    doPutFileUpload<Expense>(`/dashboard/expenses/${expenseId}`, expense),
  export: (body: ExportInvoicesInputs, organizationId: IdentifierDTO) =>
    doPost(`/dashboard/expenses/export/${organizationId}`, body),
  getAttachments: (expenseId: IdentifierDTO) => () =>
    doGet<Document[]>(`/dashboard/expenses/attachments/${expenseId}`),
  getExpenseUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/dashboard/expenses/units/organization/${organizationId}`),
  getLinkablePaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Expense[]>(toPaginatedURL(`/dashboard/expenses/linkable${params.url}${params.queryParams}`, pagination)),
  getLinkedExpenses: (expenseId: IdentifierDTO) => () =>
    doGet<Expense[]>(`/dashboard/expenses/${expenseId}/linked`),
  getOne: (expenseId: IdentifierDTO) => () =>
    doGet<Expense>(`/dashboard/expenses/${expenseId}`),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Expense[]>(toPaginatedURL(`/dashboard/expenses${params.url}${params.queryParams}`, pagination)),
  getReconcilableExpensePayersBy: (organizationId: IdentifierDTO) => () =>
    doGet<Payer[]>(`/dashboard/expenses/reconcilable/payers/organization/${organizationId}`),
  getReconcilableExpenseUnitsBy: (organizationId: IdentifierDTO, beneficiary?: BeneficiaryModel) => () =>
    doGet<BasicUnit[]>(`/dashboard/expenses/reconcilable/units/organization/${organizationId}?beneficiary=${beneficiary}`),
  getReconcilablePaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Expense[]>(toPaginatedURL(`/dashboard/expenses/reconcilable${params.url}${params.queryParams}`, pagination)),
  import: () => (formData: FormData) =>
    doPostFileUpload<void>('/dashboard/expenses/import', formData),
  linkExpenses: (body: {}) =>
    doPut('/dashboard/expenses/link-expense', body),
  removePayment: (expenseId: IdentifierDTO) => (formData: any) =>
    doDelete(`/dashboard/expenses/${expenseId}/payment/${formData.reconciliationId}`, { forced: formData.forced }),
  unlinkExpenses: (expenseId) =>
    doPut(`/dashboard/expenses/${expenseId}/unlink-expense`),
  updateToBeSettledAt: (expenseId: IdentifierDTO) => (body: any) =>
    doPut(`/dashboard/expenses/${expenseId}/to-be-settled-at`, body),
  removeToBeSettledAt: (expenseId: IdentifierDTO) =>
    doPut(`/dashboard/expenses/${expenseId}/to-be-settled-at/remove`),
  belongsToSplittedExpense: (expenseId: IdentifierDTO) => () =>
    doGet(`/dashboard/expenses/${expenseId}/belongs-to-splitted-expense`),
  getAllRelatedToSplittedExpense: (expenseId: IdentifierDTO) => () =>
    doGet<Expense[]>(`/dashboard/expenses/${expenseId}/get-all-related-to-splitted-expense`),
  removeAllRelatedSplittedExpenses: (expenseId: IdentifierDTO) =>
    doDelete(`/dashboard/expenses/${expenseId}/remove-all-splitted-expenses`)
};
